import * as React from "react";
import VisibilitySensor from "react-visibility-sensor";
import anime from "animejs";
import "./mid.scss";


const clients_bon = require("../../assets/images/clients_bon.png");
const clients_bw = require("../../assets/images/clients_bw.png");
const clients_cr = require("../../assets/images/clients_cr.png");
const clients_eldc = require("../../assets/images/clients_eldc.png");
const clients_en = require("../../assets/images/clients_en.png");
const clients_fnb = require("../../assets/images/clients_fnb.png");
const clients_mva = require("../../assets/images/clients_mva.png");
const clients_nust = require("../../assets/images/clients_nust.png");
const clients_sanlam = require("../../assets/images/clients_sanlam.png");
const clients_std = require("../../assets/images/clients_std.png");
const clients_unam = require("../../assets/images/clients_unam.png");
const clients_gen = require("../../assets/images/clients_gen.png");

const clients_bon_w = require("../../assets/images/clients_bon_w.png");
const clients_bw_w = require("../../assets/images/clients_bw_w.png");
const clients_cr_w = require("../../assets/images/clients_cr_w.png");
const clients_eldc_w = require("../../assets/images/clients_eldc_w.png");
const clients_en_w = require("../../assets/images/clients_en_w.png");
const clients_fnb_w = require("../../assets/images/clients_fnb_w.png");
const clients_mva_w = require("../../assets/images/clients_mva_w.png");
const clients_nust_w = require("../../assets/images/clients_nust_w.png");
const clients_sanlam_w = require("../../assets/images/clients_sanlam_w.png");
const clients_std_w = require("../../assets/images/clients_std_w.png");
const clients_unam_w = require("../../assets/images/clients_unam_w.png");
const clients_gen_w = require("../../assets/images/clients_gen_w.png");


type Client = {
  light: string;
  dark: string;
  title: string;
};

type Props = {
  title?: string;
  clients?: Client[];
  dark?: boolean;
};

type State = {
  isOn: boolean;
  isDark: boolean | undefined;
};

export class Mid extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOn: false,
      isDark: true
    };
  }
  componentWillMount() {
    this.setState({ isDark: this.props.dark });
  }
  public render() {
    return (
      <section
        id="clients"
        className={`midClients ${this.state.isDark ? "light" : "dark"} `}
      >
        <p className="h4 midClients__subtitle container">
          We make amazing happen for some of the countries most most ambitious
          companies.
          <span />
        </p>
        <div className="midClients__wrap">
          <span
            className="midClients__curtain"
            style={{ backgroundColor: this.state.isDark ? "black" : "white" }}
          />
          <VisibilitySensor onChange={this.display} partialVisibility={true}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="midClients__clients">
                    {this.props.clients && this.props.clients.map((client: Client, key: number) => (
                      <div key={key} className="midClients__client clearfix">
                        <img
                          src={this.state.isDark ? client.light : client.dark}
                          className="midClients__img"
                          alt={client.title}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </VisibilitySensor>
        </div>
      </section>
    );
  }

  private display = (visible: boolean) => {
    const ani = {
      easing: "easeInOutQuad",
      opacity: [0, 1]
    };
    if (visible && !this.state.isOn) {
      anime({
        ...ani,
        targets: `#clients`,
        translateY: [100, 0],
        opacity: [{value:0}, {value:1, delay: 100}],
        complete: () => {
          this.setState({ isOn: true });
          anime({
            targets: `.midClients__client`,
            ...ani,
            delay: anime.stagger(20)
          });
        }
      });
      anime({
        targets: `.midClients__curtain`,
        width: [0, `${100}%`],
        ...ani,
        delay: 0
      });
    }
  };

  // Set default props
  static defaultProps = {
    clients: [
      { title: "clients_bon", dark: clients_bon, light: clients_bon_w },
      { title: "clients_bw", dark: clients_bw, light: clients_bw_w },
      { title: "clients_cr", dark: clients_cr, light: clients_cr_w },
      { title: "clients_eldc", dark: clients_eldc, light: clients_eldc_w },
      { title: "clients_en", dark: clients_en, light: clients_en_w },
      { title: "clients_fnb", dark: clients_fnb, light: clients_fnb_w },
      { title: "clients_mva", dark: clients_mva, light: clients_mva_w },
      { title: "clients_nust", dark: clients_nust, light: clients_nust_w },
      { title: "clients_sanlam", dark: clients_sanlam, light: clients_sanlam_w },
      { title: "clients_std", dark: clients_std, light: clients_std_w },
      { title: "clients_unam", dark: clients_unam, light: clients_unam_w },
      { title: "clients_gen", dark: clients_gen, light: clients_gen_w }
    ]
  };
}
