import * as React from "react";
import anime from "animejs";
import { useWindowScroll, useUnmount } from "react-use";

type Props = {
  delay?: number;
  images: string[];
  className: string;
  baseOpacity?: number;
};

const Curtain: React.FunctionComponent<Props> = ({
  className,
  delay = 0,
  baseOpacity = 1,
  images
}) => {
  const { y } = useWindowScroll();
  const timer: { current: any } = React.useRef();
  const ref: { current: any } = React.useRef();
  const spanRef: { current: any } = React.useRef();
  const countRef: { current: any } = React.useRef(0);
  useUnmount(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  });
  React.useEffect(() => {
    setTimeout(() => {
      switchImage();
    }, delay);
  }, []);

  const switchImage = () => {
    timer.current = setInterval(() => {
      const count = countRef.current;
      if (count >= images.length - 1) {
        countRef.current = 0;
      } else {
        countRef.current = countRef.current + 1;
      }
      if (!spanRef.current || !ref.current) {
        return;
      }
      anime({
        targets: ref.current,
        translateX: [0, -30],
        opacity: [baseOpacity, 0],
        duration: 500,
        delay: anime.stagger(500),
        easing: "easeInOutQuad",
        complete: () => {
          if (!ref.current) {
            return;
          }
          ref.current.style.backgroundImage = `url('${
            images[countRef.current]
          }')`;
          anime({
            targets: ref.current,
            translateX: [30, 0],
            opacity: [0, baseOpacity],
            duration: 500,
            delay: anime.stagger(30),
            easing: "easeInOutQuad"
          });
        }
      });
    }, 5000);
  };

  return (
    <div
      className={className}
      ref={ref}
      style={{
        backgroundImage: `url('${images[countRef.current]}')`
      }}
    >
      <span
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          backgroundColor: "white"
        }}
        ref={spanRef}
      ></span>
    </div>
  );
};

export default Curtain;
