import * as React from "react";
import anime from "animejs";
import VisibilitySensor from "react-visibility-sensor";
import Slider from "react-slick";
import { ChevronDown, Link as LinkIcon } from "react-feather";
import { Link, utils } from "../";
import "./project.scss";

type Props = {
  title: string;
  tag?: string;
  subtitle: string;
  text?: string;
  images?: {
    title: string;
    src: string;
  }[];
  isMain?: boolean;
  links?: {
    to: string;
    name: string;
  }[];
};

export const Project = (props: Props) => {
  const [isOn, reveal] = React.useState(false);
  const [isOpen, open] = React.useState(props.isMain);
  const hasContent = props.links && props.images;
  const id = `project__${utils.uuid()}`;
  const display = (visible: boolean) => {
    if (visible && !isOn) {
      anime({
        targets: `#${id}`,
        opacity: [0, 1],
        duration: 500,
        easing: "easeInOutQuad",
        complete: () => {
          reveal(true);
        }
      });
    }
  };

  const toggle = () => {
    if (props.isMain || !hasContent) {
      return;
    }
    const height = utils.getElementHeightsByAll([
      `#${id} .project__article-links`,
      `#${id} .project__article-text`
    ]);

    anime({
      targets: `#${id} .project__article-wrapped`,
      height: !isOpen ? [`${0}`, `${height}`] : [`${height}`, `${0}`],
      duration: 500,
      easing: "easeInOutQuad",
      complete: () => {
        open(isOpen ? false : true);
      }
    });
  };

  const settings = {
    dots: false,
    className: "project__article-slider",
    infinite: true,
    speed: 500,
    fade: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };

  return (
    <article
      id={id}
      className={`project__article ${props.isMain ? "main" : ""}  ${hasContent ? "" : "mincontent"}`}
    >
      <VisibilitySensor onChange={display} partialVisibility={true}>
        <div className="container">
          <div className="row">
            <div className={`${hasContent ? "col-md-7" : "col-12"}`}>
              <div className="project__article-content">
                {!!props.tag && (
                  <p className="project__article-tag">{props.tag}</p>
                )}
                <div className="project__article-toggle">
                  {props.isMain ? (
                    <h2 className="project__article-title">{props.title}</h2>
                  ) : (
                    <h3 className="project__article-title">{props.title}</h3>
                  )}
                  {!props.isMain && hasContent && (
                    <span
                      className="h3 project__article-chev"
                      style={{
                        transform: `rotate(${isOpen ? "180" : "0"}deg)`
                      }}
                    >
                      <ChevronDown />
                    </span>
                  )}
                </div>
                {props.isMain ? (
                  <h5 className="project__article-subtitle">
                    {props.subtitle}
                  </h5>
                ) : (
                  <p className="project__article-subtitle">{props.subtitle}</p>
                )}

                <div
                  className={`project__article-wrapped ${
                    isOpen ? "active" : ""
                  }`}
                >
                  {!!props.text && (
                    <p className="project__article-text">{props.text}</p>
                  )}

                  <ul className="project__article-links">
                    {props.links &&
                      props.links.map((e, i) => (
                        <li key={i}>
                          <Link className="project__article-link" to={e.to}>
                            <LinkIcon
                              className="project__article-link-icon"
                              size="20"
                            />
                            {e.name}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
            {hasContent && (
              <div className="col-md-5">
                {props.isMain && props.images ? (
                  <Slider {...settings}>
                    {props.images.map((img, i) => (
                      <img
                        key={i}
                        className="project__article-image"
                        src={img.src}
                        alt={
                          !!img.title
                            ? `${img.title}`
                            : `${props.title} image number ${i + 1}`
                        }
                      />
                    ))}
                  </Slider>
                ) : (
                  props.images && (
                    <div className="project__article-preview">
                      <img
                        src={props.images[0].src}
                        alt={
                          !!props.images[0].title
                            ? `${props.images[0].title}`
                            : `${props.title} image number 1`
                        }
                      />
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      </VisibilitySensor>
    </article>
  );
};
