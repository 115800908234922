import * as React from "react";
import VisibilitySensor from "react-visibility-sensor";
import anime from "animejs";

import "./mid.scss";

type Props = {};
type State = {
  revealed: boolean;
  selected: any;
};

export class Mid extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      revealed: false,
      selected: null
    };
  }
  public render() {
    return (
      <section className="midServices">
        <VisibilitySensor onChange={this.reveal} partialVisibility={true}>
          <div className="container">MID</div>
        </VisibilitySensor>
      </section>
    );
  }

  private reveal = (isVisible: boolean) => {
    if (isVisible && !this.state.revealed) {
      this.setState({ revealed: true });
    }
  };
}
