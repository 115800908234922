import * as React from "react";
import anime from "animejs";
import VisibilitySensor from "react-visibility-sensor";
import { utils } from "../";
import "./mast.scss";

type Props = {
  title: string;
  image?: string;
};

export const Mast = ({ title, image }: Props) => {
  const [isOn, reveal] = React.useState(false);
  const display = (visible: boolean) => {
    if (visible && !isOn) {
      const height = window.innerHeight;
      const titleH = utils.getElementHeights([".mast__title"]) || height / 2;
      const line = document.querySelector(".mast__line") as any;
      const main = document.querySelector(".mast") as any;
      main.style.height = `${height}px`;
      // main.style.height = `${height + (height / 3)}px`;
      line.style.top = `${titleH + 20}px`;
      anime({
        targets: ".mast",
        opacity: [0, 1],
        duration: 100,
        easing: "easeInOutQuad",
        complete: () => {
          assemble(height);
          reveal(true);
        }
      });
    }
  };

  const assemble = (height: number) => {
    const el = document.querySelector(".mast__img") as any;
    const easing = { easing: "easeInOutQuad", delay: 250 };
    if (el) {
      el.style.height = `${(height / 3) * 1.8}px`;
      el.style.top = `${height}px`;
      anime({
        targets: `.mast__line`,
        height: [0, `${height / 2 + height / 10}px`],
        // height: [0, `${height}px`],
        ...easing
      });
      anime({
        targets: `.mast__img`,
        top: [`${height}px`, `${height / 2}px`],
        ...easing,
        complete: () => {
          window.addEventListener("scroll", () => {
            const pos = window.pageYOffset;
            let top = height / 2 - pos / 0.8;
            if (top < 90) {
              return;
            }
            el.style.top = `${top}px`;
          });
        }
      });
    }
  };

  return (
    <div data-color="light" className="section">
      <VisibilitySensor onChange={display} partialVisibility={true}>
        <div className="mast">
          <span
            role="img"
            aria-label={`${title} image`}
            className="mast__img"
            style={{ backgroundImage: `url('${image}')` }}
          />
          <div className="container">
            <h1 className="mast__title">{title}</h1>
            <div className="mast__line" />
          </div>
        </div>
      </VisibilitySensor>
    </div>
  );
};
