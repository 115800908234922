import * as React from "react";
import VisibilitySensor from "react-visibility-sensor";
import anime from "animejs";
import "./list.scss";

import { GEAR } from "../../content";

type Props = {
  dark?: boolean;
};

export const List = (props: Props) => {
  const [isOn, reveal] = React.useState(false);
  const display = (visible: boolean) => {
    if (visible && !isOn) {
      anime({
        targets: `.gearList`,
        opacity: [0, 1],
        delay: 0,
        translateY: [100, 0],
        easing: "easeInOutQuad",
        complete: () => {
          reveal(true);
          anime({
            targets: `.gearList__curtain`,
            width: [`${0}%`, `${100}%`],
            duration: 450,
            easing: "easeInOutQuad"
          });
          anime({
            targets: `.gearList__item-name`,
            opacity: [0, 1],
            delay: anime.stagger(80),
            easing: "easeInOutQuad"
          });
        }
      });
    }
  };
  return (
    <section className={`gearList ${props.dark ? "dark" : ""}`}>
      <h5 className="gearList__text">
        Peek into our equipment locker
        <span />
      </h5>
      <VisibilitySensor onChange={display} partialVisibility={true}>
        <div className="gearList__wrap">
          <span className="gearList__curtain" />
          <div className="container">
            <div className="gearList__items">
              <ul className="row">
                {GEAR.tech.map((item, key: number) => (
                  <li className="gearList__item" key={key}>
                    <p className="gearList__item-name">{item}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </VisibilitySensor>
    </section>
  );
};
