import * as React from "react";
import VisibilitySensor from "react-visibility-sensor";
import anime from "animejs";

import "./contact.scss";
import { Button } from "../index";
type Props = {
  title?: string;
  email?: string;
  buttonText?: string;
  buttonLink?: string;
};

export const Contact = (props: Props) => {
  const [isOn, reveal] = React.useState(false);
  const display = (visible: boolean) => {
    if (visible && !isOn) {
      anime({
        targets: ".contactUs__content",
        opacity: [0, 1],
        translateY: [30, 0],
        duration: 500,
        delay: 300,
        easing: "easeInOutQuad",
        complete: () => reveal(true)
      });
      anime({
        targets: ".contactUs__bg",
        duration: 400,
        width: `${100}%`,
        easing: "linear"
      });
    }
  };
  const email = !!props.email
    ? `mailto:{props.email}`
    : "mailto:info@ubuntuevents.co?subject=Hello&nbsp;Team!";
  return (
    <section data-color="light" className="section contactUs">
      <span className="contactUs__bg" />
      <VisibilitySensor onChange={display} partialVisibility={true}>
        <div className="container contactUs__content">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h2 className="contactUs__title">
                {!!props.title ? props.title : "Lets get started."}
              </h2>
            </div>
            <div className="col-md-4 text-right">
              <h5>
                <a className="contactUs__mail" href={email}>
                  {!!props.email ? props.email : "info@ubuntuevents.co"}
                </a>
              </h5>
            </div>
            <div className="col-md-4 text-center">
              <div className="contactUs__bookWrap">
                <Button
                  size="large"
                  to="/quote"
                  className="contactUs__book"
                  text="Book now"
                  icon="check"
                />
                <span className="span_line" />
              </div>
            </div>
            <div className="col-md-4">
              <h6>
                <a className="contactUs__call mt" href="tel:+264 81 390 8191">
                  Hadino +264 81 390 8191
                </a>
              </h6>
              <h6>
                <a className="contactUs__call" href="tel:+264 81 129 1151">
                  Fernando +264 81 129 1151
                </a>
              </h6>
            </div>
          </div>
        </div>
      </VisibilitySensor>
    </section>
  );
};
