import * as React from "react";
import { useWindowSize, useWindowScroll } from "react-use";
import "./vsr.scss";

import { Button } from "../base";
import Curtain from "./curtain";
import { Clients } from "..";

const img_1 = require("../../assets/images/hero/hero_a-min.jpg");
const img_2 = require("../../assets/images/hero/hero_b-min.jpg");
const img_3 = require("../../assets/images/hero/hero_c-min.jpg");
const img_4 = require("../../assets/images/hero/hero_d-min.jpg");
const img_5 = require("../../assets/images/hero/hero_e-min.jpg");
const img_6 = require("../../assets/images/hero/hero_f-min.jpg");
const img_7 = require("../../assets/images/hero/hero_g-min.jpg");
const img_8 = require("../../assets/images/hero/hero_h-min.jpg");
const img_9 = require("../../assets/images/hero/hero_i-min.jpg");
const img_10 = require("../../assets/images/hero/hero_j-min.jpg");
const img_11 = require("../../assets/images/hero/hero_k-min.jpg");

const primary = [img_1, img_11, img_2, img_3];
const secondary = [img_4, img_5, img_6, img_7, img_8, img_10];

type Props = { index?: number };

export const Vsr: React.FC<Props> = ({ index = 0 }) => {
  const idx = React.useRef(index);
  const { y } = useWindowScroll();
  const { width, height } = useWindowSize();

  const isWide = width < 1200;
  const isMobile = width < 768;
  const img = primary[idx.current];
  return (
    <div
      className="heroVsr"
      style={{ minHeight: isMobile ? 0 : `${height}px` }}
    >
      <div className="heroVsr__top">
        <div className={`container${isWide ? "-fluid" : ""}`}>
          <div className="row">
            <div
              className="col-sm-12 col-md-8"
              style={{ transform: `translateY(${-(y * 0.4)}px)` }}
            >
              <div className="heroVsr__cta">
                <h1 className="heroVsr__cta-title">
                  Passionate <br />
                  about your
                  <br /> events
                </h1>
                <h5 className="heroVsr__cta-subtitle">
                  A flexible and creative partner in creating inspiring &
                  engaging experiences.
                </h5>
                <Button
                  text="Book us"
                  to="/quote"
                  size={isWide ? "small" : "large"}
                  bgColor="#e91e63"
                  textColor="white"
                  className="heroVsr__cta-book"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isMobile && (
        <div style={{ pointerEvents: "none" }} className="heroVsr__clients">
          <Clients.Min />
        </div>
      )}
      <span className="heroVsr__dots" />
      {isMobile && (
        <div
          className="heroVsr__mobile"
          style={{ backgroundImage: `url('${img ? img : primary[0]}')` }}
        />
      )}

      {isMobile && <span className="heroVsr__overlay" />}

      {!isMobile && <span className="heroVsr__fade_tp" />}
      {!isMobile && <span className="heroVsr__fade_bt" />}
      {!isMobile && (
        <div className="heroVsr__cover">
          <span
            className="heroVsr__cover_a"
            style={{
              backgroundImage: `url('${img ? img : primary[0]}')`
            }}
          ></span>

          <Curtain className="heroVsr__cover_b" images={secondary} />
        </div>
      )}
    </div>
  );
};
