import * as React from "react";
import anime from "animejs";
import { ChevronDown } from "react-feather";
import VisibilitySensor from "react-visibility-sensor";
import "./full.scss";

import { getElementHeights, timings } from "../utils";

type caseStudyService = {
  title: string;
  gear: string[];
};

type Props = {
  client: string;
  text: string;
  services: caseStudyService[];
  images: { src: string }[];
};

export const Full: React.FunctionComponent<Props> = ({
  client,
  text,
  services,
  images
}) => {
  const id = `${Math.random()
    .toString(36)
    .substr(2, 9)}`;
  const [isOn, reveal] = React.useState(false);
  const display = (visible: boolean) => {
    if (visible && !isOn) {
      anime({
        targets: `.study__${id}`,
        opacity: [0, 1],
        easing: "easeInOutQuad",
        complete: () => {
          reveal(true);
          anime({
            targets: ".caseStudy__image-curtain",
            height: ["100%", `${0}%`],
            duration: 500,
            delay: anime.stagger(200),
            easing: "easeInOutQuad"
          });
        }
      });
    }
  };

  const handleToggle = (id: string) => {
    const height = getElementHeights([`.caseStudy__meta-${id}`]) as any;
    const wHeight = getElementHeights([`.caseStudy__meta-${id} .wrap`]) as any;

    let active = true;
    if (!height || height === 0) {
      active = false;
    }
    anime({
      targets: `.caseStudy__meta-${id}`,
      height: active ? [`${0}px`] : `${wHeight}px`,
      opacity: active ? [1, 0] : [0, 1],
      duration: 250,
      easing: "easeInOutQuad"
    });

    anime({
      targets: `.caseToggle__${id}`,
      rotate: active ? [`${180}deg`, 0] : [0, `${180}deg`],
      duration: 250,
      easing: "easeInOutQuad"
    });
  };

  const remixImages = (id: string) => {};

  return (
    <div className={`caseStudy study__${id}`}>
      <VisibilitySensor onChange={display} partialVisibility={true}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="caseStudy__details">
                <p className="caseStudy__subtitle">case study</p>
                <h4 className="caseStudy__title">{client}</h4>
                <p className="caseStudy__subtext">{text}</p>

                {services.map((service, key: number) => (
                  <div key={key} className="caseStudy__meta">
                    <button
                      onClick={handleToggle.bind(null, `${key}`)}
                      className="caseStudy__meta-btn"
                    >
                      <h6>{service.title}</h6>
                      <ChevronDown className={`caseToggle__${key}`} />
                    </button>
                    <div
                      className={`caseStudy__meta-content caseStudy__meta-${key}`}
                    >
                      <div className="wrap">
                        {service.gear.map((p, i: number) => (
                          <p key={i}>{p}</p>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-10 col-lg-8">
              <div className="caseStudy__images">
                {images.map((image, key: number) => (
                  <div
                    key={key}
                    className={`caseStudy__image caseStudy__image_${key}`}
                  >
                    <span className="caseStudy__image-curtain" />
                    <img src={image.src} alt="ubuntuevents image" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </VisibilitySensor>
    </div>
  );
};
