import { equals } from "ramda";
import * as React from "react";
import Slider from "react-slick";
import VisibilitySensor from "react-visibility-sensor";
import { ArrowRight } from "react-feather";
import anime from "animejs";

import "./full.scss";
import * as utils from "../utils";
import { SERVICES, Service } from "../../content";

const Light = require("../../assets/svg/lighting.svg");
const AudioVisual = require("../../assets/svg/av.svg");
const Conference = require("../../assets/svg/conference.svg");
const Sales = require("../../assets/svg/sales.svg");
const Sound = require("../../assets/svg/sound.svg");
const Stage = require("../../assets/svg/stage.svg");

type Props = {
  services: Service[];
};
type State = {
  revealed: boolean;
  activated: boolean;
  locked: boolean;
  selected: Service | undefined;
};

export class Full extends React.Component<Props, State> {
  private sliderRef: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      revealed: false,
      locked: false,
      selected: undefined,
      activated: false
    };
  }
  public render() {
    const { selected } = this.state;
    const settings = {
      accessibility: true,
      dots: false,
      className: "fullServices__slider",
      infinite: true,
      autoplaySpeed: 8000,
      arrows: false,
      focusOnSelect: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      onSwipe: () => this.activate(),
      afterChange: (index: number) => this.toggle(index),
      responsive: [
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 570,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 340,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <section data-color="light" className="section fullServices">
        <VisibilitySensor onChange={this.reveal} partialVisibility={true}>
          <div className="fullServices__wrap">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div
                    className={`fullServices__header ${
                      selected ? "active" : ""
                    }`}
                  >
                    <h2 className="fullServices__header-title">
                      Explore our services
                    </h2>
                    <h5 className="fullServices__header-subtitle">
                      Each service tailored and executed to ensure your event is
                      a success
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="fullServices__services">
              <Slider ref={this.setRef} {...settings}>
                {this.props.services.map((s, i) => (
                  <div className="fullServices__service-wrap" key={i}>
                    <button
                      className="fullServices__service"
                      onClick={this.select.bind(null, s)}
                    >
                      <span
                        style={{ backgroundColor: `${s.color}` }}
                        className="fullServices__service-bg"
                      />
                      <span
                        className="fullServices__service-icon"
                        style={{ backgroundColor: `${s.color}` }}
                      >
                        {this.getIcon(s.id, s.color)}
                      </span>
                      <h5 className="fullServices__service-title">{s.title}</h5>
                      <p className="fullServices__service-subtitle">{s.text}</p>
                      <span className="fullServices__service-arrow">
                        <ArrowRight />
                      </span>
                    </button>
                  </div>
                ))}
              </Slider>
            </div>
            <div
              style={{ height: 0 }}
              className={`fullServices__footer ${selected ? "active" : ""}`}
            >
              <div className="container">
                <h6 className="fullServices__footer-subtitle">
                  {selected && selected.info}
                  <span
                    style={{ backgroundColor: `${selected && selected.color}` }}
                  />
                </h6>
              </div>
              {selected && <hr className="fullServices__footer-line" />}
            </div>
          </div>
        </VisibilitySensor>
      </section>
    );
  }

  private activate = () => {
    this.setState({ activated: true });
  };

  private toggle = (i?: number) => {
    const { activated } = this.state;
    if (activated && i) {
      this.select(this.props.services[i]);
    }
  };

  private select = (curr?: Service) => {
    if (equals(curr, this.state.selected) || this.state.locked) return;
    this.setState({ locked: true });
    if (curr) {
      anime({
        targets: ".fullServices__footer-subtitle",
        opacity: 0,
        easing: "easeInOutQuad",
        duration: 300,
        delay: 0,
        complete: () => {
          this.setState({ activated: true, selected: curr }, () => {
            const height = utils.getElementHeights([
              ".fullServices__footer-subtitle"
            ]);
            anime({
              targets: ".fullServices__footer",
              height: !curr ? 0 : `${height}px`,
              easing: "easeInOutQuad"
            });
            anime({
              targets: ".fullServices__footer-subtitle",
              opacity: 1,
              easing: "easeInOutQuad",
              complete: () => this.setState({ locked: false })
            });
          });
        }
      });
    } else {
      const height = utils.getElementHeights([
        ".fullServices__footer-subtitle"
      ]);
      anime({
        targets: ".fullServices__footer-subtitle",
        opacity: [1, 0],
        easing: "easeInOutQuad",
        complete: () => {
          anime({
            targets: ".fullServices__footer",
            height: !curr ? 0 : `${height}px`,
            easing: "easeInOutQuad",
            complete: () =>
              this.setState({ activated: true, selected: curr, locked: false })
          });
        }
      });
    }
  };

  private setRef = (e: Slider) => (this.sliderRef = e);
  private reveal = (isVisible: boolean) => {
    if (isVisible && !this.state.revealed) {
      anime({
        delay: 0,
        targets: ".fullServices",
        opacity: [0, 1],
        easing: "easeInOutQuad",
        complete: () => {
          this.setState({ revealed: true });
          anime({
            targets: [".fullServices__header", ".fullServices__service"],
            opacity: [0, 1],
            translateY: [30, 0],
            easing: "easeInOutQuad",
            duration: 300,
            delay: anime.stagger(80)
          });
        }
      });
    }
  };

  private getIcon = (id: string, fill: string): React.ReactElement | null => {
    switch (id) {
      case "f_sound":
        return <Sound fill={fill} />;
      case "f_stage":
        return <Stage fill={fill} />;
      case "f_lighting":
        return <Light fill={fill} />;
      case "f_av":
        return <AudioVisual fill={fill} />;
      case "f_conf":
        return <Conference fill={fill} />;
      case "f_sandi":
        return <Sales fill={fill} />;
      default:
        return null;
    }
  };

  // Set default props
  static defaultProps = {
    services: SERVICES
  };
}
