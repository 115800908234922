import * as React from "react";
import * as utils from "../utils";

interface ILinkProps {
  children: React.ReactNode;
  to: string;
  className?: string;
  [key: string]: any;
}

export const Link = ({ children, style, to, ...props }: ILinkProps) => {
  const isLink = typeof to !== "undefined";
  const isExternal = isLink && /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(to || "");
  const nav = (to: string) =>  utils.navTo(to);

  if (isExternal) {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={to}
        {...props}
        style={{
          textDecoration: "none",
          color: "rgb(0,0,0,.8)",
          ...style
        }}
      >
        {children}
      </a>
    );
  }

  return (
    <a
      onClick={nav.bind(null, to)}
      {...props}
      style={{
        textDecoration: "none",
        color: "rgb(0,0,0,.8)",
        ...style
      }}
    >
      {children}
    </a>
  );
};
