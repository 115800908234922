import * as React from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Target, Shield, Compass } from "react-feather";
import charming from "charming";
import anime from "animejs";
import "./full.scss";

import { VALUES } from "../../content";
const LogoMark = require("../../assets/svg/logo_black.svg");

type Props = {};

type State = {
  isOn: boolean;
};

export class Full extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOn: false
    };
  }
  componentDidMount() {
    VALUES.forEach((_, i) => {
      const el = document.querySelector(`.fullValues__title_${i}`);
      if (el) {
        charming(el, {
          tagName: "span"
        });
      }
    });
  }

  public render() {
    return (
      <section data-color="light" className={`section fullValues`}>
        <span className="fullValues__texture" />
        <VisibilitySensor onChange={this.display} partialVisibility={true}>
          <div className="fullValues__wrap">
            <div className="container">
              <div className="row">
                {VALUES.map((v, i) => {
                  return (
                    <div
                      key={i}
                      className={`${
                        i == 0 ? "col-md-12 npLR" : "col-md-6 npLR"
                      }`}
                    >
                      <div className={`t_${i} fullValues__item`}>
                        {i == 0 && (
                          <span className="fullValues__mark">
                            <LogoMark />
                          </span>
                        )}
                        <div className={`fullValues__item-header`}>
                          {/* <img src={v.image} className={`fullValues__img`} /> */}
                          <p
                            className={`h2 fullValues__title fullValues__title_${i}`}
                          >
                            {v.title}
                          </p>
                          <h5 className="fullValues__tag">{v.tag}</h5>
                          <span className="fullValues__icon odd">
                            {this.getIcon(v.id)}
                          </span>
                        </div>

                        <p className="h6 fullValues__text">{v.text}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </VisibilitySensor>
      </section>
    );
  }

  private getIcon = (id: string): React.ReactElement | null => {
    switch (id) {
      case "value_0":
        return <Shield size={50} />;
      case "value_1":
        return <Compass size={50} />;
      case "value_2":
        return <Target size={50} />;

      default:
        return null;
    }
  };

  private display = (visible: boolean) => {
    if (visible && !this.state.isOn) {
      anime({
        targets: `.fullValues`,
        opacity: [0, 1],
        // translateY: [100, 0],
        easing: "easeInOutQuad",
        complete: () => {
          this.setState({ isOn: true });
          anime.timeline({ loop: false }).add({
            targets: ".fullValues__title span",
            translateY: [100, 0],
            translateZ: 0,
            opacity: [0, 1],
            easing: "easeOutExpo",
            delay: function(_, i) {
              return 200 + 30 * i;
            }
          });
          anime({
            targets: ".fullValues__text",
            opacity: [0, 1],
            translateY: [50, 0],
            delay: anime.stagger(100),
            easing: "easeOutExpo"
          });
          anime({
            targets: ".fullValues__tag",
            opacity: [0, 1],
            delay: anime.stagger(100),
            easing: "easeOutExpo"
          });
        }
      });
    }
  };
}
