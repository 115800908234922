import * as React from "react";
import anime from "animejs";
import { Target, Shield, Compass } from "react-feather";
import VisibilitySensor from "react-visibility-sensor";
import "./mid.scss";

import * as utils from "../utils";

import { Link, Button } from "../index";
import { VALUES } from "../../content";

type Props = {
  dark?: boolean;
  index?: 0 | 1 | 2;
  rtl?: boolean;
};
export const Mid: React.FunctionComponent<Props> = ({
  dark,
  index,
  rtl
}: Props) => {
  const value = VALUES[index || 0];
  const image = value.image;
  const id = `${Math.random()
    .toString(36)
    .substr(2, 9)}`;
  const [isReversed, reverse] = React.useState(false);
  const [isOn, reveal] = React.useState(false);
  const checkSize = () => reverse(Boolean(rtl && utils.sizeAbove(768)));
  const display = (visible: boolean) => {
    if (visible && !isOn) {
      checkSize();
      anime({
        delay: 300,
        targets: `.midval__main-curtain`,
        width: `${0}%`,
        easing: "easeInOutQuad",
      });
      anime({
        targets: `.midval__${id}`,
        opacity: [0, 1],
        easing: "easeInOutQuad",
        complete: () => {
          reveal(true);
          anime({
            targets: [".midval__bg-curtain", ".midval__center-curtain"],
            width: `${0}%`,
            delay: anime.stagger(80),
            easing: "easeInOutQuad"
          });
          anime({
            targets: ".midval__right",
            translateX: [-80, 0],
            opacity: [0, 1],
            delay: 100,
            easing: "easeInOutQuad"
          });
          anime({
            targets: [
              ".midval__center-title",
              ".midval__center-tag",
              ".midval__center-btn"
            ],
            translateY: [20, 0],
            opacity: [0, 1],
            delay: anime.stagger(80),
            easing: "easeInOutQuad"
          });
        }
      });
    }
  };

  const getIcon = (title: string): React.ReactElement | null => {
    switch (title) {
      case "philosophy":
        return <Shield size={35} />;
      case "vision":
        return <Compass size={35} />;
      case "mission":
        return <Target size={35} />;
      default:
        return null;
    }
  };

  const renderMain = () => (
    <div className="col-md-8">
      <div
        className="midval__bg"
        style={{ backgroundImage: `url('${image}')` }}
      >
        <div className="midval__bg-curtain" />
      </div>
      <Link className="midval__center" to="/about">
        <h5 className="midval__center-title">{value.title}</h5>
        <h3 className="midval__center-tag">{value.tag}</h3>
        <Button size="small" text="About us" className="midval__center-btn" />
        <div className="midval__center-curtain" />
      </Link>
    </div>
  );
  const renderPrime = () => (
    <div className="col-md-4" style={{ float: "left" }}>
      <div className="midval__right">
        <p className="midval__right-text">{utils.trunc(value.text, 160)}</p>
        {getIcon(value.title)}
      </div>
    </div>
  );

  return (
    <div
      data-color={`${dark ? "dark" : "light"}`}
      className={`section midval midval__${id} ${dark ? "dark" : ""}`}
    >
      <span className="midval__main-curtain" />
      <VisibilitySensor onChange={display} partialVisibility={true}>
        <>
          <span
            className="midval__cover"
            style={{
              backgroundImage: `url('${image}')`
            }}
          />
          <span className="midval__dots" />
          <div className="container">
            {isReversed ? (
              <div className="row">
                {renderPrime()}
                {renderMain()}
              </div>
            ) : (
              <div className="row">
                {renderMain()}
                {renderPrime()}
              </div>
            )}
          </div>
        </>
      </VisibilitySensor>
    </div>
  );
};
