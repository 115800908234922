import { equals } from "ramda";
import * as React from "react";
import MediaQuery from "react-responsive";
import VisibilitySensor from "react-visibility-sensor";
import { X } from "react-feather";
import anime from "animejs";
import "./full.scss";

import { TEAM, Team as TeamType } from "../../content";
import * as utils from "../utils";
const MIN = 769;

type Props = {
  title: string;
  subtitle: string;
  members: TeamType[];
  limit?: boolean;
};

type State = {
  isOn: boolean;
  selected: any;
};

export class Full extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOn: false,
      selected: null
    };
  }

  public render() {
    const { title, subtitle, members } = this.props;
    const { selected } = this.state;

    return (
      <div id="fullTeam" data-color="dark" className="section fullTeam">
        <span className="fullTeam__bg" />
        <span className="fullTeam__dots" />
        <VisibilitySensor onChange={this.reveal} partialVisibility={true}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="fullTeam__header">
                  <h2 className="fullTeam__header-title"> {title}</h2>
                  <h5 className="fullTeam__header-subtitle">{subtitle}</h5>
                  <span className="fullTeam__header-line" />
                </div>
              </div>
              <div className="col-12">
                <div className="row fullTeam__teamates">
                  {members.map((t, i) => (
                    <div key={i} className="col-sm-6 col-md-4 col-lg-3">
                      <button
                        onClick={this.toggle.bind(null, t)}
                        key={i}
                        className={`fullTeam__teamate ${
                          selected === t ? "active" : ""
                        }`}
                      >
                        <p className="fullTeam__teamate-name">{t.name}</p>
                        <p className="fullTeam__teamate-title">{t.title}</p>
                      </button>
                    </div>
                  ))}
                </div>
                <MediaQuery minWidth={MIN}>
                  {selected && (
                    <div
                      className="fullTeam__details"
                      style={{ backgroundColor: this.getColor() }}
                    >
                      {!!selected.avatar && (
                        <div className="fullTeam__details-img">
                          <img src={selected.avatar} alt={selected.name} />
                        </div>
                      )}
                      <p className="fullTeam__details-name">{selected.name}</p>
                      <p className="fullTeam__details-title">
                        {selected.title}
                      </p>
                      <p className="fullTeam__details-desc">
                        {selected.description}
                      </p>
                      <button
                        className="fullTeam__details-btn"
                        onClick={this.toggle.bind(null, null)}
                      >
                        <X />
                      </button>
                      <span className="fullTeam__details-curtain" />
                    </div>
                  )}
                </MediaQuery>
              </div>
            </div>
          </div>
        </VisibilitySensor>
      </div>
    );
  }

  private toggle = (selected: TeamType | null) => {
    if (
      (!equals(selected, this.state.selected) && utils.sizeAbove(MIN)) ||
      (!selected && this.state.selected)
    ) {
      if (selected && this.state.selected) {
        this.switch(selected);
        return;
      }
      if (!selected) {
        anime({
          targets: "#fullTeam .fullTeam__details-curtain",
          width: selected ? `${0}%` : `${100}%`,
          delay: 0,
          duration: 500,
          easing: "easeInOutQuad"
        });
        anime({
          targets: "#fullTeam .fullTeam__details",
          opacity: selected ? 1 : 0,
          delay: 0,
          easing: "easeInOutQuad"
        });
      }
      anime({
        targets: "#fullTeam .fullTeam__teamates",
        width: selected ? `${70}%` : `${100}%`,
        delay: selected ? 0 : 500,
        duration: 500,
        easing: "easeInOutQuad",
        complete: () => {
          this.setState({ selected });
          anime({
            targets: "#fullTeam .fullTeam__details",
            opacity: selected ? 1 : 0,
            delay: 0,
            easing: "easeInOutQuad"
          });
          if (selected) {
            anime({
              targets: "#fullTeam .fullTeam__details-curtain",
              width: selected ? `${0}%` : `${100}%`,
              delay: 0,
              easing: "easeInOutQuad"
            });
          }
        }
      });
    }
  };

  private switch = (selected: TeamType | null) => {
    const ani = {
      targets: [
        "#fullTeam .fullTeam__details-img",
        "#fullTeam .fullTeam__details-name",
        "#fullTeam .fullTeam__details-title",
        "#fullTeam .fullTeam__details-desc",
        "#fullTeam .fullTeam__details-btn"
      ],
      delay: 0,
      duration: 500,
      easing: "easeInOutQuad"
    };
    anime({
      ...ani,
      translateX: [0, 30],
      opacity: [1, 0],
      complete: () => {
        this.setState({ selected });
        anime({
          ...ani,
          translateX: [30, 0],
          opacity: [0, 1]
        });
      }
    });
  };
  private getColor = (): string => {
    const colors = [
      "#d36ec6",
      "#ff6382",
      "#9085da",
      "#38d996",
      "#4fadff",
      "#ffab9d"
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  private reveal = (isVisible: boolean) => {
    if (isVisible && !this.state.isOn) {
      anime({
        targets: "#fullTeam",
        opacity: [0, 1],
        delay: 0,
        duration: 500,
        easing: "easeInOutQuad",
        complete: () => this.setState({ isOn: true })
      });
    }
  };

  // Set default props
  static defaultProps = {
    ...TEAM
  };
}
