import * as React from "react";
import anime from "animejs";
import { type, findIndex, propEq } from "ramda";
import { ArrowDown, Briefcase, ChevronDown, MapPin } from "react-feather";
import animateScrollTo from "animated-scroll-to";
import VisibilitySensor from "react-visibility-sensor";
import "./min.scss";

import * as utils from "../utils";
import { SHOWCASES, Showcase } from "../../content";

type Props = {
  showcases: Showcase[];
};

type State = {
  revealed: boolean;
  open: boolean;
  showcase: Showcase;
};

export class Min extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      revealed: false,
      open: false,
      showcase: this.props.showcases[0]
    };
  }

  public render() {
    const { showcase } = this.state;
    const key = 0;

    return (
      <section data-color="light"  className="section minStudy">
        <div className="minStudy__wrap">
          <span className="minStudy__curtain" />
          <VisibilitySensor onChange={this.display} partialVisibility={true}>
            <div className={`container case_${key}`}>
              <button onClick={this.handleNext} className="minStudy__next">
                <ArrowDown />
              </button>
              <article className="minStudy__body">
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <div className="minStudy__content">
                      <div className="minStudy__content-wrap">
                        {/* <p className="minStudy__label">CASE STUDY</p> */}
                        <h3 className="minStudy__title">{showcase.event}</h3>
                        {showcase.location && (
                          <h5 className="minStudy__subtitle">
                            <MapPin /> {showcase.location}
                          </h5>
                        )}
                        {showcase.client && (
                          <h5 className="minStudy__subtitle">
                            <Briefcase /> {showcase.client}
                          </h5>
                        )}
                        {showcase && (
                          <button
                            className="minStudy__toggle"
                            onClick={this.handleOpen.bind(null, undefined)}
                          >
                            {this.state.open ? "Less details" : "More details"}{" "}
                            <ChevronDown className="minStudy__icon" />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="minStudy__imgs">
                      <span
                      className="minStudy__imgs-bg"
                        style={{backgroundImage: `url('${showcase.image}')`}}
                        id="minStudy__mainImage"
                      />
                      {/* <img
                        src={showcase.image}
                        id="minStudy__mainImage"
                        alt="gear used"
                      /> */}
                      <span className="minStudy__imgs-curtain" />
                    </div>
                  </div>
                  <div className="minStudy__services">
                    <span className="minStudy__services-line" />
                    <h6 className="minStudy__meta-subtitle">
                      Gear we used. . .
                    </h6>
                    <div className={`row gearBody`}>
                      {showcase.services &&
                        showcase.services.map((service, key: number) => (
                          <div key={key} className="col-12 col-sm-4">
                            <div key={key} className="minStudy__meta">
                              <h6 className="minStudy__meta-title">
                                {service.title}
                              </h6>
                              <div
                                className={`minStudy__meta-content minStudy__meta-${key}`}
                              >
                                <div className="wrap">
                                  {service.gear.map((p, i: number) => (
                                    <p className="minStudy__meta-gear" key={i}>
                                      {p}
                                    </p>
                                  ))}
                                </div>
                              </div>
                              {service.image && (
                                <button
                                  onClick={this.switchImage.bind(
                                    null,
                                    service.image
                                  )}
                                  className="minStudy__meta-img"
                                >
                                  <img
                                    src={service.image}
                                    alt="gear used button"
                                  />
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </VisibilitySensor>
        </div>
      </section>
    );
  }

  private handleCurtain = (up?: boolean, cb?: () => void) => {
    const anim = {
      targets: ".minStudy__curtain",
      easing: "easeInOutQuad",
      duration: 250
    };
    if (up) {
      anime({
        ...anim,
        height: [`${0}%`, `${100}%`],
        delay: 0
      });
      anime({
        ...anim,
        targets: ".minStudy__body",
        opacity: [1, 0],
        complete: () => {
          if (cb && type(cb) === "Function") {
            cb();
          }
        }
      });
    } else {
      anime({
        ...anim,
        height: [`${100}%`, `${0}%`]
      });
      anime({
        ...anim,
        targets: ".minStudy__body",
        opacity: [0, 1],
        complete: () => {
          if (cb && type(cb) === "Function") {
            cb();
          }
        }
      });
    }
  };

  private handleNext = () => {
    const { showcase, open } = this.state;
    const { showcases } = this.props;
    const index = findIndex(propEq("id", showcase.id), showcases);
    if (index >= 0) {
      let next = showcases[0];
      if (showcases[index + 1]) {
        next = showcases[index + 1];
      }
      if (open) {
        this.handleOpen(() =>
          this.handleCurtain(true, () =>
            this.setState({ showcase: next }, this.handleCurtain)
          )
        );
      } else {
        this.handleCurtain(true, () =>
          this.setState({ showcase: next }, this.handleCurtain)
        );
      }
    }
  };

  private switchImage = (e: string) => {
    const image = document.getElementById("minStudy__mainImage") as any;
    const main = document.querySelector(".minStudy__content") as any;
    const anim = {
      targets: ".minStudy__imgs-curtain",
      duration: 400,
      easing: "easeInOutQuad"
    };

    if (image && e && image.src != e) {
      utils.throttle(() => {
        anime({
          ...anim,
          height: [`${0}%`, `${110}%`],
          complete: () => {
            image.src = e;
            animateScrollTo(main, { minDuration: 250 });
            anime({
              ...anim,
              height: [`${110}%`, `${0}%`]
            });
          }
        });
      }, 1000)();
    }
  };

  private handleOpen = (cb?: () => void) => {
    // const heightA = utils.getElementHeightsByClass(`.gearBody__${key}`);
    const heightA = utils.getElementHeights([`.gearBody`]);

    let height = heightA;
    if (utils.isMobile() && heightA) {
      height = heightA + 90;
    } else if (!utils.isMobile() && heightA) {
      height = heightA + 120;
    }
    const { open } = this.state;
    const anim = {
      targets: `.minStudy__services`,
      duration: 400,
      easing: "easeInOutQuad"
    };

    if (open) {
      anime({
        ...anim,
        targets: [
          `.minStudy__meta-title`,
          `.minStudy__meta-img`,
          `.minStudy__meta-gear`
        ],
        opacity: [1, 0],
        delay: anime.stagger(20)
      });
      anime({
        ...anim,
        targets: `.minStudy__icon`,
        rotate: [180, 0]
      });
      anime({
        ...anim,
        height: [`${height}px`, `${0}px`],
        complete: () => {
          const wrap = document.querySelector(`.minStudy__content`) as any;
          if (wrap) {
            animateScrollTo(wrap, { minDuration: 1000 });
          }
          this.setState({ open: false });
        }
      });
      if (cb && type(cb) === "Function") {
        cb();
      }
    } else {
      anime({
        ...anim,
        targets: [
          `.minStudy__meta-title`,
          `.minStudy__meta-img`,
          `.minStudy__meta-gear`
        ],
        translateX: [50, 0],
        opacity: [0, 1],
        delay: anime.stagger(20)
      });
      anime({
        ...anim,
        targets: `.minStudy__icon`,
        rotate: [0, 180]
      });
      anime({
        ...anim,
        height: [`${0}px`, `${height}px`],
        complete: () => {
          const wrap = document.querySelector(`.minStudy__services`) as any;
          if (wrap) {
            animateScrollTo(wrap, { minDuration: 1000 });
          }
          this.setState({ open: true });
        }
      });
    }
  };

  private display = (isVisible: boolean) => {
    if (isVisible && !this.state.revealed) {
      anime({
        targets: ".minStudy__body",
        opacity: [0, 1],
        translateY: [100, 0],
        easing: "easeInOutQuad",
        complete: () => this.setState({ revealed: true })
      });
      anime({
        targets: ".minStudy__imgs-curtain",
        height: [`${110}%`, `${0}%`],
        easing: "easeInOutQuad"
      });
    }
  };

  static defaultProps = {
    showcases: SHOWCASES
  };
}
