import * as React from "react";
import anime from "animejs";
import VisibilitySensor from "react-visibility-sensor";
import { Button } from "..";
import "./full.scss";

import { EXPERTISE, Expertise as Area } from "../../content";

export const Full = () => {
  const [isOn, reveal] = React.useState(false);
  const display = (visible: boolean) => {
    if (visible && !isOn) {
      anime({
        targets: ".expertiseFull",
        opacity: [0, 1],
        duration: 500,
        easing: "easeInOutQuad",
        complete: () => {
          anime({
            targets: [
              ".expertiseFull__area-label",
              ".expertiseFull__area-title"
            ],
            translateY: [30, 1],
            opacity: [0, 1],
            delay: anime.stagger(50),
            easing: "easeInOutQuad"
          });
          reveal(true);
        }
      });
    }
  };

  return (
    <div data-color="light" className="section expertiseFull">
      <VisibilitySensor onChange={display} partialVisibility={true}>
        <div className="container">
          <div className="row expertiseFull__areas">
            {EXPERTISE.map((e: Area, i) => (
              <div
                key={i}
                className="expertiseFull__area-wrap col-6 col-sm-4 npLR clearfix"
              >
                <div className="expertiseFull__area">
                  <div className="expertiseFull__area-content">
                    <span className="expertiseFull__area-label">{e.label}</span>
                    <h5 className="expertiseFull__area-title">{e.title}</h5>
                  </div>
                  <span className="expertiseFull__booking">
                    <Button
                      outline={true}
                      text="Get Quote"
                      to="/quote"
                      size="small"
                      className="expertiseFull__book"
                    />
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </VisibilitySensor>
    </div>
  );
};
