import * as React from "react";
import { Instagram, Twitter, Mail, Phone } from "react-feather";

import "./footer.scss";
const Logomark = require("../../assets/svg/logomark_allblack.svg");
import { Link } from "../index";
import { MENU } from "../../content";

export const Footer = () => (
  <footer id="footer" data-color="light" className="section footer">
    <div className="container">
      <div className="row">
        <div className="col-md-8">
          <Logomark className="footer__logo" />
          {MENU.links.map((e, i) => (
            <Link key={i} className="footer__link" to={e.to}>
              {e.title}
            </Link>
          ))}
        </div>
        <div className="col-md-4 footer__socials">
          <Link className="footer__social footer__phone" to="/">
            <span />
            <Phone />
          </Link>
          <Link
            alt="ubuntu events email link"
            className="footer__social"
            to="mailto:info@ubuntuevents.co?subject=Hello&nbsp;Team!"
          >
            <span />
            <Mail />
          </Link>

          <Link
            alt="instagram link"
            className="footer__social"
            to="https://www.instagram.com/ubuntu_events_technology/"
          >
            <span />
            <Instagram />
          </Link>
          <Link
            alt="twitter link"
            className="footer__social"
            to="https://twitter.com/Ubuntu_Events"
          >
            <span />
            <Twitter />
          </Link>
        </div>
      </div>
    </div>
    <div className="footer__copyright">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <p className="footer__copyright-text">
              All rights reserved {new Date().getFullYear()} © ubuntu events
              technology | made with ♥ from Namibia
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
);
