import * as React from "react";
import VisibilitySensor from "react-visibility-sensor";
import Slider from "react-slick";
import MediaQuery from "react-responsive";
import { ArrowRight, ArrowLeft, ArrowUp, Mail, Phone } from "react-feather";
import anime from "animejs";
import "./mid.scss";

import { Button } from "../base/button";
import { TEAM, Team as TeamType } from "../../content";
import { getElementHeights, sizeAbove } from "../utils";

const MIN = 769;

type Props = {
  title: string;
  subtitle: string;
  members: TeamType[];
  limit?: boolean;
  cover?: string;
};

type State = {
  revealed: boolean;
  selected: any;
};

export class Mid extends React.Component<Props, State> {
  private sliderRef: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      revealed: false,
      selected: null
    };
  }

  public render() {
    const { title, subtitle, members, cover } = this.props;
    const { selected } = this.state;

    const settings = {
      dots: false,
      className: "midTeam__slider",
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      beforeChange: (_: any, i: number) => this.toggle(i),
      arrows: false,
      responsive: [
        {
          breakpoint: 457,
          settings: { slidesToShow: 3, slidesToScroll: 1 }
        },
        {
          breakpoint: 470,
          settings: { slidesToShow: 4, slidesToScroll: 1 }
        },
        {
          breakpoint: 768,
          settings: { slidesToShow: 4, slidesToScroll: 1 }
        }
      ]
    };

    const redirect = this.getMembers().length !== members.length;

    return (
      <div data-color="light" id="midTeam" className="section midTeam">
        <div className="midTeam__wrap">
          <VisibilitySensor onChange={this.reveal} partialVisibility={true}>
            <>
              <MediaQuery minWidth={992}>
                <span
                  className="midTeam__image"
                  style={{
                    backgroundImage: `url('${
                      selected && !!selected.avatar ? selected.avatar : cover
                    }')`
                  }}
                />
              </MediaQuery>
              <MediaQuery maxWidth={992}>
                <span className="midTeam__pattern" />
              </MediaQuery>
              <div className="container">
                <div className="row">
                  <div className="col-md-5 col-lg-4">
                    <div className="midTeam__teamates">
                      <MediaQuery maxWidth={768}>
                        <Slider ref={this.setRef} {...settings}>
                          {this.renderMembers()}
                        </Slider>
                        {this.getMembers().length > 4 && (
                          <div className="midTeam__controls">
                            <button
                              type="button"
                              onClick={this.handleSlide.bind(null, true)}
                              className="midTeam__controls-next"
                              aria-label="next slide"
                            >
                              <ArrowRight width={20} />
                            </button>
                          </div>
                        )}
                      </MediaQuery>
                      <MediaQuery minWidth={768}>
                        {this.renderMembers()}
                      </MediaQuery>
                    </div>
                  </div>
                  <div className="col-md-7 col-lg-8">
                    <div className="midTeam__details">
                      {!selected ? (
                        <h3 className="midTeam__details-title">
                          <MediaQuery maxWidth={768}>
                            <ArrowUp size={20} />
                          </MediaQuery>
                          <MediaQuery minWidth={768}>
                            <ArrowLeft size={20} />
                          </MediaQuery>
                          {title}
                        </h3>
                      ) : (
                        <h4 className="midTeam__details-title">
                          {selected.name}
                        </h4>
                      )}
                      <h5 className="midTeam__details-subtitle">
                        {selected ? selected.title : subtitle}
                      </h5>

                      {selected && (
                        <>
                          <p className="midTeam__details-text">
                            {selected.description}
                          </p>
                          {selected.email && (
                            <a
                              href={selected.email}
                              className="midTeam__details-link"
                            >
                              <Mail />
                            </a>
                          )}
                          {selected.phone && (
                            <a
                              href={selected.phone}
                              className="midTeam__details-link"
                            >
                              <Phone />
                            </a>
                          )}
                        </>
                      )}
                      <Button
                        to="/about"
                        className="midTeam__details-allBttn"
                        text="View full team"
                        outline={true}
                        textColor="#a9a9a9"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          </VisibilitySensor>
        </div>
      </div>
    );
  }

  private renderMembers = () =>
    this.getMembers().map((t: any, i: number) => (
      <div key={i} className="midTeam__teamate-block">
        <button
          onClick={this.toggle.bind(null, i)}
          className={`midTeam__teamate ${
            this.state.selected === t ? "active" : ""
          }`}
          disabled={this.state.selected === t}
        >
          <span className="midTeam__teamate-wrap">
            {t.image ? (
              <img src={t.avatar || t.image} alt={t.name} />
            ) : (
              <p>{t.name.substring(0, 2)}</p>
            )}
          </span>
        </button>
      </div>
    ));

  private getMembers = () => {
    const { members, limit } = this.props;
    if (limit) {
      return members.filter(m => !!m.image);
    } else {
      return members;
    }
  };
  private setRef = (e: Slider) => (this.sliderRef = e);
  private handleSlide = (next?: boolean) => {
    if (this.sliderRef) {
      if (next) {
        this.sliderRef.slickNext();
      } else {
        this.sliderRef.slickPrev();
      }
    }
  };

  private toggle = (index: number) => {
    if (sizeAbove(MIN) && this.state.selected) {
      anime({
        targets: ".midTeam__image",
        opacity: 0,
        delay: 0,
        duration: 10,
        easing: "linear",
        translateX: [0, 50]
      });
    }
    anime({
      targets: ".midTeam__details",
      opacity: [1, 0],
      translateX: [0, 50],
      delay: 0,
      easing: "easeInOutQuad",
      endDelay: 180,
      duration: 200,
      complete: () => {
        this.showDetails(this.getMembers()[index]);
      }
    });
  };

  showDetails = (selected: any) => {
    this.setState({ selected }, () => {
      const { offsetHeight } =
        document && (document.querySelector(".midTeam__details") as any);
      const height_b = getElementHeights([
        ".midTeam__details-text",
        ".midTeam__details-link",
        ".midTeam__details-title",
        ".midTeam__details-subtitle",
        ".midTeam__details-allBttn"
      ]) as any;

      anime({
        targets: ".midTeam__details",
        opacity: [0, 1],
        translateX: [-50, 0],
        height: [offsetHeight, height_b + 60],
        easing: "easeInOutQuad",
        duration: 200
      });

      if (sizeAbove(MIN)) {
        anime({
          targets: ".midTeam__image",
          opacity: [0, 0.5],
          delay: 500,
          duration: 200,
          easing: "linear",
          translateX: [50, 0]
        });
      }

      if (sizeAbove(MIN)) {
        const height_c = getElementHeights([".midTeam__teamates"]) as any;
        const padNum = (height_b - height_c) / 2;
        anime({
          targets: [".midTeam__teamates"],
          easing: "easeInOutQuad",
          duration: 200,
          translateY: [padNum < 0 ? 0 : padNum]
        });
      }
    });
  };

  private reveal = (isVisible: boolean) => {
    if (isVisible && !this.state.revealed) {
      anime({
        targets: "#midTeam",
        opacity: [0, 1],
        delay: 0,
        duration: 500,
        easing: "easeInOutQuad",
        complete: () => this.setState({ revealed: true })
      });
    }
  };

  // Set default props
  static defaultProps = {
    ...TEAM
  };
}
