import * as React from "react";
import VisibilitySensor from "react-visibility-sensor";
import anime from "animejs";
import { ArrowRight } from "react-feather";

import { trunc } from "../utils";

import "./min.scss";


type Props = {
};

export const Min = () => {
  const [isOn, reveal] = React.useState(false);
  const display = (visible: boolean) => {
    if (visible && !isOn) {
      anime({
        targets: [".services .wrap", ".service__mini"],
        opacity: [0,1],
        easing: "easeInOutQuad",
        complete: () => reveal(true)
      });
    }
  };

  return (
    <section className={`minServices`}>
      <VisibilitySensor onChange={display} partialVisibility={true}>
        <div className="container">
          Min
        </div>
      </VisibilitySensor>
    </section>
  );
};
