import * as React from "react";
import anime from "animejs";
import { X } from "react-feather";
import VisibilitySensor from "react-visibility-sensor";
import animateScrollTo from "animated-scroll-to";
import "./services.scss";

import { SERVICES, Service } from "../../content";

import Light from "../../assets/svg/lighting.svg";
import AudioVisual from "../../assets/svg/av.svg";
import Conference from "../../assets/svg/conference.svg";
import Sales from "../../assets/svg/sales.svg";
import Sound from "../../assets/svg/sound.svg";
import Stage from "../../assets/svg/stage.svg";

const ANIM = {
  targets: ".fservices__bg",
  easing: "easeInOutQuad",
  duration: 300
};

type Props = {
  expertise?: Service[];
  title?: string;
};

export interface State {
  isOn: boolean;
  hasSelected: Service | null;
}

export class Services extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOn: false,
      hasSelected: null
    };
  }

  public render() {
    const { hasSelected } = this.state;
    return (
      <div data-color="light" className="section fservices">
        {hasSelected && (
          <div className="fservices__overlay">
            <div className="container">
              <div className="fservices__overlay-content">
                <button
                  onClick={this.HandleClose}
                  className="fservices__overlay-close"
                >
                  <X />
                </button>
                <span className="fservices__overlay-icon">
                  {this.getIcon(hasSelected.id)}
                </span>
                <div className="fservices__overlay-content">
                  <h5 className="fservices__overlay-title">
                    {hasSelected.title}
                  </h5>
                  <h6 className="fservices__overlay-info">
                    {hasSelected.info}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        )}
        <span className="fservices__bg" />
        <VisibilitySensor onChange={this.display} partialVisibility={true}>
          <div className="container">
            <h5 className="fservices__title">
              {!!this.props.title ? this.props.title : "We specialise in"}
            </h5>
            <div className="row">
              {SERVICES.map((e, i) => (
                <div key={i} className="col-sm-6 col-lg-4 clearfix">
                  <div
                    className={`fservices__service ${
                      i % 2 == 0 ? "even" : ""
                    } c__${i}`}
                  >
                    <button onClick={this.HandleSelected.bind(null, e)}>
                      <div className="fservices__service-header">
                        <div
                          className="fservices__service-image"
                          style={{ backgroundImage: `url('${e.image}')` }}
                        />
                      </div>
                      <span
                        className={`fservices__service-icon fservices_icon_${e.id}`}
                      >
                        {this.getIcon(e.id)}
                      </span>
                      <div className="fservices__service-content">
                        <h5
                          data-text={e.title}
                          className="fservices__service-title"
                        >
                          {e.title}
                        </h5>
                        <h6 className="fservices__service-info">{e.text}</h6>
                      </div>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </VisibilitySensor>
      </div>
    );
  }

  private display = () => {
    if (!this.state.isOn) {
      anime({
        targets: ".fservices",
        opacity: [0, 1],
        duration: 500,
        easing: "easeInOutQuad",
        complete: () => this.setState({ isOn: true })
      });
    }
  };

  private getIcon = (id: string): React.ReactElement | null => {
    switch (id) {
      case "f_sound":
        return <Sound />;
      case "f_stage":
        return <Stage />;
      case "f_lighting":
        return <Light />;
      case "f_av":
        return <AudioVisual />;
      case "f_conf":
        return <Conference />;
      case "f_sandi":
        return <Sales />;
      default:
        return null;
    }
  };
  private HandleSelected = (item: any) => {
    const icon = document.querySelector(`.fservices_icon_${item.id}`) as any;
    const BG = document.querySelector(`.fservices__bg`) as any;
    if (icon && BG) {
      const rect = icon.getBoundingClientRect();
      BG.style.top = `${rect.top}px`;
      BG.style.left = `${rect.left}px`;
      BG.style.height = `${rect.height}px`;
      BG.style.width = `${rect.width}px`;

      switch (item.id) {
        case "f_sound":
          BG.style.backgroundColor = "#d36ec6";
          break;
        case "f_stage":
          BG.style.backgroundColor = "#ff6382";
          break;
        case "f_lighting":
          BG.style.backgroundColor = "#9085da";
          break;
        case "f_av":
          BG.style.backgroundColor = "#38d996";
          break;
        case "f_conf":
          BG.style.backgroundColor = "#4fadff";
          break;
        case "f_sandi":
          BG.style.backgroundColor = "#ffab9d";
          break;
      }

      anime({
        opacity: [0, 1],
        scale: [1, 60],
        ...ANIM
      });
    }

    this.setState({ hasSelected: item }, () => {
      if (this.state.isOn) {
        const main = document.querySelector(`.fservices`) as any;
        if (main) {
          animateScrollTo(main, { minDuration: 250 });
        }
        anime({
          targets: ".fservices__overlay",
          opacity: [0, 1],
          delay: 0,
          easing: "easeInOutQuad"
        });
        anime({
          targets: [".fservices__overlay-title", ".fservices__overlay-info"],
          opacity: [0, 1],
          translateY: [50, 0],
          delay: anime.stagger(50),
          easing: "easeInOutQuad"
        });
      }
    });
  };

  private HandleClose = () => {
    anime({
      ...ANIM,
      opacity: [1, 0],
      scale: [60, 1],
      dela: 100
    });
    anime({
      targets: ".fservices__overlay",
      opacity: [1, 0],
      duration: 300,
      easing: "easeInOutQuad",
      complete: () => {
        this.setState({ hasSelected: null });
      }
    });
  };
}
