import * as React from "react";
import VisibilitySensor from "react-visibility-sensor";
import anime from "animejs";
import "./brands.scss";

import { GEAR } from "../../content";


// type Props = {};

export const Brands = () => {
  const [isOn, reveal] = React.useState(false);
  const display = (visible: boolean) => {
    if (visible && !isOn) {
      anime({
        targets: `.gearBrands`,
        opacity: [0, 1],
        translateY: [100, 0],
        easing: "easeInOutQuad",
        complete: () => reveal(true)
      });
    }
  };
  return (
    <section className="gearBrands">
      <VisibilitySensor onChange={display} partialVisibility={true}>
        <div className="container">
        <h5 className="gearBrands__text">Some of our favourite brands
        <span /></h5>
          <div className="gearBrands__brands">
            <ul className="row">
              {GEAR.brands.map((brand, key: number) => (
                <li className="gearBrands__brand" key={key}>
                  <img src={brand.src} alt={brand.title} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </VisibilitySensor>
    </section>
  );
};
