import * as React from "react";
import anime from "animejs";
import VisibilitySensor from "react-visibility-sensor";
import Slider from "react-slick";
import { ArrowRight, ArrowLeft } from "react-feather";

import "./mid.scss";
import { REVIEWS, FLOATS, Review } from "../../content";
// import { utils } from "../";

type Props = {
  dark?: boolean;
};
type State = {
  isOn: boolean;
  curr: Review;
};

export class Mid extends React.Component<Props, State> {
  private sliderRef: any;
  private circleRef: any;
  constructor(props: Props) {
    super(props);

    this.state = {
      isOn: false,
      curr: REVIEWS[0]
    };
  }
  public componentDidMount() {
    // var tl = anime.timeline({
    //   easing: "easeOutExpo",
    //   duration: 8000,
    //   loop: true
    // });
    // // Add children
    // tl.add({
    //   targets: this.circleRef,
    //   scale: [0.1, 5],
    //   opacity: [0, 1]
    // });
    // tl.add({
    //   targets: this.circleRef,
    //   opacity: 0
    // });
    // tl.add({
    //   targets: this.circleRef,
    //   scale: 1,
    //   duration: 0
    //   // opacity: 0
    // });
    // anime({
    //   targets: this.circleRef,
    //   scale: [0.1, 5],
    //   duration: 8000,
    //   opacity: [0, 1],
    //   easing: "easeInOutQuad",
    //   complete:()=>{
    //     anime({
    //       targets: this.circleRef,
    //       scale: 0.1,
    //       duration: 100,
    //       opacity: 0,
    //       easing: "easeInOutQuad",
    //     });
    //   }
    // });
  }
  public render() {
    const settings = {
      accessibility: true,
      dots: false,
      className: "minFeedback__slider",
      infinite: true,
      autoplaySpeed: 8000,
      fade: true,
      autoplay: true,
      arrows: false,
      focusOnSelect: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: this.handleCircle
    };
    return (
      <div
        data-color={this.props.dark ? "dark" : "light"}
        className={`section minFeedback ${this.props.dark ? "dark" : ""} ${
          this.props.dark ? "dark" : ""
        }`}
      >
        <VisibilitySensor onChange={this.display} partialVisibility={true}>
          <div className="container">
            {/* <span className="minFeedback__bg-icon">&ldquo;</span> */}
            {/* <span className="minFeedback__bg" /> */}
            <span className="minFeedback__img circle" />
            <span className="minFeedback__img circle super" />
            <span
              ref={ref => (this.circleRef = ref)}
              className="minFeedback__img"
              style={{
                backgroundImage: `url('${this.state.curr.img}')`
              }}
            >
              &ldquo;
            </span>
            <Slider ref={this.setRef} {...settings}>
              {REVIEWS.map((e, i) => (
                <div key={i} className="">
                  <div className="minFeedback__item">
                    <p className="h4 minFeedback__text">{e.text}</p>
                    <div className="minFeedback__sub">
                      <p className="minFeedback__title">{e.title}</p>
                      <p className="minFeedback__name">{e.name}</p>
                      {/* <span className="minFeedback__icon">&ldquo;</span> */}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            <div className="minFeedback__controls">
              <button
                type="button"
                onClick={this.handleSlide.bind(null, false)}
                className="minFeedback__controls-prev"
                aria-label="previous slide"
              >
                <ArrowLeft width={20} />
              </button>
              <button
                type="button"
                onClick={this.handleSlide.bind(null, true)}
                className="minFeedback__controls-next"
                aria-label="next slide"
              >
                <ArrowRight width={20} />
              </button>
            </div>
          </div>
        </VisibilitySensor>
      </div>
    );
  }

  private setRef = (e: any) => (this.sliderRef = e);
  private handleCircle = (oldIndex: number, newIndex: number) => {
    const easing = "easeOutExpo";
    anime({
      targets: this.circleRef,
      scale: 0.3,
      duration: 200,
      opacity: 0.3,
      easing,
      complete: () => {
        this.setState({ curr: REVIEWS[newIndex] }, () => {
          anime({
            targets: this.circleRef,
            scale: 1,
            opacity: 0.5,
            duration: 500,
            easing
          });
        });
      }
    });
  };
  private handleSlide = (next?: boolean) => {
    if (this.sliderRef) {
      if (next) {
        this.sliderRef.slickNext();
      } else {
        this.sliderRef.slickPrev();
      }
    }
  };
  private display = () => {
    if (!this.state.isOn) {
      anime({
        targets: ".minFeedback",
        opacity: [0, 1],
        duration: 500,
        delay: 500,
        easing: "easeInOutQuad",
        complete: () => {
          this.setState({ isOn: true });
        }
      });
    }
  };
}
