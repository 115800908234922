import * as React from "react";
import VisibilitySensor from "react-visibility-sensor";
import { ArrowRight, ArrowLeft, ArrowDown } from "react-feather";
import MediaQuery from "react-responsive";
import anime from "animejs";
import "./value.scss";

type Props = {
  value: string;
  text: string;
  rtl?: boolean;
};

export const Single = ({ value, text, rtl }: Props) => {
  const [isOn, reveal] = React.useState(false);
  const id = value.replace(/ /g, "").substr(0, 10);
  const display = (visible: boolean) => {
    if (visible && !isOn) {
      anime({
        targets: `.value.${id}`,
        opacity: [0, 1],
        translateY: [100, 0],
        easing: "easeInOutQuad",
        complete: () => reveal(true)
      });
    }
  };

  return (
    <section className={`value ${id} ${rtl ? "right" : "left"} `}>
      <span className="value__texture" />
      <VisibilitySensor onChange={display} partialVisibility={true}>
        <div className="value__wrap">
          <div className="container">
            <div className="row">
              <div className="col-sm-3">
                <h3 className="value__title">
                  <MediaQuery maxWidth={567}>
                    <ArrowDown className="value__pointer down" />
                  </MediaQuery>
                  <MediaQuery minWidth={567}>
                    {rtl ? (
                      <ArrowLeft className="value__pointer" />
                    ) : (
                      <ArrowRight className="value__pointer" />
                    )}
                  </MediaQuery>
                  {value}
                  {isOn}
                </h3>
              </div>
              <div className="col-sm-9">
                <h5 className="value__text">{text}</h5>
                {/* <Button className="value__btn" text="more..." /> */}
              </div>
            </div>
          </div>
        </div>
      </VisibilitySensor>
    </section>
  );
};
