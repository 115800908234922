import * as React from "react";
import anime from "animejs";
import VisibilitySensor from "react-visibility-sensor";
import "./main.scss";

import * as utils from "../utils";

import { SERVICES } from "../../content";
import { Button } from "../base/button";

const Logo = require("../../assets/svg/logo_color.svg");
const floats_a = require("../../assets/images/floats_a.jpg");
const floats_b = require("../../assets/images/floats_b.jpg");
const floats_c = require("../../assets/images/floats_c.jpg");
const floats_d = require("../../assets/images/floats_d.jpg");
const floats_e = require("../../assets/images/floats_e.jpg");
const floats_f = require("../../assets/images/floats_f.jpg");

const images = [floats_a, floats_b, floats_c, floats_d, floats_e, floats_f];

export type Area = {
  icon: any;
  id: string;
  title: string;
  image: string | null;
  info: string;
  text: string;
  color: string;
};

type Props = {
  services: Area[];
};

type State = {
  isOn: boolean;
  floats: string[];
};

export class Main extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOn: false,
      floats: images
    };
  }
  // componentDidMount() {
  //   return;
  // }

  public render() {
    const { floats } = this.state;
    return (
      <div className="hero">
        <VisibilitySensor onChange={this.display} partialVisibility={true}>
          <>
            <div className="hero__top">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="hero__cta">
                      <span className="hero__cta-logo">
                        <Logo />
                      </span>
                      <h1 className="hero__cta-title">
                        Passionate about <br />
                        your events
                      </h1>
                      <h5 className="hero__cta-subtitle">
                        A flexible and creative partner in creating inspiring &
                        engaging experiences.
                      </h5>
                      <Button
                        text="Book us"
                        to="/quote"
                        size="xlarge"
                        bgColor="#e91e63"
                        textColor="white"
                        className="hero__cta-book"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <span className="hero__point hero__point-a" />
            <span className="hero__point hero__point-b" />
            <span className="hero__point hero__point-c" />
            <span className="hero__point hero__point-d" />
            <span className="hero__point hero__point-e" />
            <span className="hero__point hero__point-f" />
            <span className="hero__point hero__point-g" />

            <div className="hero__bubble hero__a">
              <span
                className="hero__bubble-img hero__a-img"
                style={{ backgroundImage: `url(${floats[0]})` }}
                onMouseLeave={utils.throttle(this.shuff, 400)}
              />
            </div>
            <div className="hero__bubble hero__b">
              <span
                className="hero__bubble-img hero__b-img"
                style={{ backgroundImage: `url(${floats[1]})` }}
                onMouseLeave={utils.throttle(this.shuff, 400)}
              />
            </div>
            <div className="hero__bubble hero__c">
              <span
                className="hero__bubble-img hero__c-img"
                style={{ backgroundImage: `url(${floats[2]})` }}
                onMouseLeave={utils.throttle(this.shuff, 400)}
              />
            </div>
            <div className="hero__bubble hero__d">
              <span
                className="hero__bubble-img hero__d-img"
                style={{ backgroundImage: `url(${floats[3]})` }}
                onMouseLeave={utils.throttle(this.shuff, 400)}
              />
            </div>
            <div className="hero__bubble hero__e" />
            <div id="cursor" className="wt-cursor" />
          </>
        </VisibilitySensor>
      </div>
    );
  }

  private shuff = () =>
    this.setState({ floats: utils.shuffle(this.state.floats) });
  private display = () => {
    if (!this.state.isOn) {
      anime({
        targets: `#hero`,
        opacity: [0, 1],
        delay: 200,
        easing: "easeInOutQuad",
        complete: () => this.setState({ isOn: true })
      });
    }
  };

  private cursor = () => {
    if (!this.state.isOn) {
      document.onmousemove = handleMouseMove;
      function handleMouseMove(event: any) {
        const el = document.getElementById("cursor");
        let eventDoc, doc, body;
        event = event || window.event; // IE-ism

        // If pageX/Y aren't available and clientX/Y are,
        // calculate pageX/Y - logic taken from jQuery.
        // (This is to support old IE)
        if (event.pageX == null && event.clientX != null) {
          eventDoc = (event.target && event.target.ownerDocument) || document;
          doc = eventDoc.documentElement;
          body = eventDoc.body;

          event.pageX =
            event.clientX +
            ((doc && doc.scrollLeft) || (body && body.scrollLeft) || 0) -
            ((doc && doc.clientLeft) || (body && body.clientLeft) || 0);
          event.pageY =
            event.clientY +
            ((doc && doc.scrollTop) || (body && body.scrollTop) || 0) -
            ((doc && doc.clientTop) || (body && body.clientTop) || 0);
        }
        if (el) {
          el.style.left = event.pageX + "px";
          el.style.top = event.pageY + "px";
        }

        // Use event.pageX / event.pageY here
      }
    }
  };

  static defaultProps = {
    services: SERVICES
  };
}
