import * as React from "react";
import VisibilitySensor from "react-visibility-sensor";
import anime from "animejs";
// import Slider from "react-slick";
import "./min.scss";

import * as utils from "../utils";
import { EXPERTISE, Expertise } from "../../content";

type Props = {};
const init: undefined | Expertise = EXPERTISE[0];
export const Min: React.FC<Props> = props => {
  const [isOn, reveal] = React.useState(false);
  const [service, setService] = React.useState(init);
  const display = (visible: boolean) => {
    if (visible && !isOn) {
      anime({
        targets: ".expertiseMin",
        opacity: [0, 1],
        easing: "easeInOutQuad",
        duration: 500,
        complete: () => reveal(true)
      });
    }
  };

  return (
    <section className={`expertiseMin`}>
      <VisibilitySensor onChange={display} partialVisibility={true}>
        <ul className="expertiseMin__areas">
          {EXPERTISE.map((e: Expertise, n) => (
            <li key={n}>
              <div className="expertiseMin__area">
                {/* <span className="expertiseMin__area-icon">
                          <CheckCircle />
                        </span> */}
                <p className="expertiseMin__label">{e.label}</p>
                <p className="h3 expertiseMin__title">{e.title}</p>
              </div>
            </li>
          ))}
          {EXPERTISE.map((e: Expertise, n) => (
            <li key={`-${n}`}>
              <div className="expertiseMin__area">
                {/* <span className="expertiseMin__area-icon">
                          <CheckCircle />
                        </span> */}
                <p className="expertiseMin__label">{e.label}</p>
                <p className="h3 expertiseMin__title">{e.title}</p>
              </div>
            </li>
          ))}
        </ul>
      </VisibilitySensor>
    </section>
  );
};
