import * as React from "react";
import anime from "animejs";
import VisibilitySensor from "react-visibility-sensor";
import "./alt.scss";

import * as utils from "../utils";

import { SERVICES } from "../../content";
import { Button } from "../base/button";

const Logo = require("../../assets/svg/logo_color.svg");
const img_1 = require("../../assets/images/pitch_night_1.jpg");
const img_2 = require("../../assets/images/pitch_night_2.jpg");

export type Area = {
  icon: any;
  id: string;
  title: string;
  image: string | null;
  info: string;
  text: string;
  color: string;
};

type Props = {
  services: Area[];
};

type State = {
  isOn: boolean;
};

export class Alt extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOn: false
    };
  }

  public componentWillMount() {}
  public componentDidMount() {
    // anime({
    //   targets: `.heroAlt__bgImage`,
    //   // opacity: [0, 0.3],
    //   delay: 200,
    //   duration: 2000,
    //   easing: "easeInOutQuad"
    // });
  }

  public render() {
    return (
      <div className="heroAlt">
        <VisibilitySensor onChange={this.display} partialVisibility={true}>
          <>
            <div className="heroAlt__bg" />
            <div
              className="heroAlt__bgImage"
              style={{ backgroundImage: `url('${img_2}')` }}
            >
              {/* <img alt="img_1" src={img_1} /> */}
            </div>
            <div className="heroAlt__top">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="heroAlt__cta">
                      <span className="heroAlt__cta-logo">
                        <Logo />
                      </span>
                      <h1 className="heroAlt__cta-title">
                        Passionate <br />
                        about your
                        <br /> events
                      </h1>
                      <h5 className="heroAlt__cta-subtitle">
                        A flexible and creative partner in creating inspiring &
                        engaging experiences.
                      </h5>
                      <Button
                        text="Book us"
                        to="/quote"
                        size="xlarge"
                        bgColor="#e91e63"
                        textColor="white"
                        className="heroAlt__cta-book"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </VisibilitySensor>
      </div>
    );
  }

  private display = () => {
    if (!this.state.isOn) {
      anime({
        targets: `#heroAlt`,
        opacity: [0, 1],
        delay: 200,
        easing: "easeInOutQuad",
        complete: () => this.setState({ isOn: true })
      });
    }
  };

  static defaultProps = {
    services: SERVICES
  };
}
