import * as React from "react";
import VisibilitySensor from "react-visibility-sensor";
import Slider from "react-slick";
import Img from "gatsby-image";
import anime from "animejs";

// import MediaQuery from "react-responsive";
import { Heart, Instagram as InstagramIcon, ArrowRight } from "react-feather";
import "./instagram.scss";
import { Link } from "../index";

// import { getElementHeights, isMobile, sizeAbove } from "../utils";

type Props = {
  data: any;
};

type State = {
  revealed: boolean;
};

export class Instagram extends React.Component<Props, State> {
  private slider: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      revealed: false
    };
  }
  public render() {
    const def = { allInstaNode: { edges: [] } };
    const {
      data: {
        allInstaNode: { edges }
      }
    } = this.props || def;

    const settings = {
      className: "instagram__slider",
      arrows: false,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 570,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
    };
    return (
      <section id="instagram" className="instagram">
        <VisibilitySensor onChange={this.reveal} partialVisibility={true}>
          <div className="container">
            <Slider {...settings} ref={slider => (this.slider = slider)}>
              {edges.map((item: any, key: number) => (
                <div key={key} className="instagram__post">
                  <Img
                    fluid={item.node.localFile.childImageSharp.fluid}
                    fadeIn={true}
                    className="instagram__post-img"
                  />
                  <button className="instagram__post-heart">
                    <Heart />
                  </button>
                </div>
              ))}
            </Slider>
            <button onClick={this.handleNext} className="instagram__next">
              <span>Swipe</span> <ArrowRight />
            </button>
            <div className="instagram__info">
              <table>
                <tbody>
                  <tr>
                    <th>
                      <Link
                        to="https://www.instagram.com/ubuntu_events_technology/"
                        className="instagram__info-btn"
                      >
                        <InstagramIcon
                          size={32}
                          className="instagram__info-icon"
                        />
                        <h5 className="instagram__info-title">Follow us</h5>
                        {/* <h6 className="instagram__info-subtitle">ubuntu events technology</h6> */}
                      </Link>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </VisibilitySensor>
      </section>
    );
  }

  private handleNext = () => {
    if (this.slider) {
      this.slider.slickNext();
    }
  };

  private reveal = (isVisible: boolean) => {
    if (isVisible && !this.state.revealed) {
      anime({
        targets: "#instagram",
        opacity: [0, 1],
        translateY: [100, 0],
        easing: "easeInOutQuad",
        complete: () => this.setState({ revealed: true })
      });
    }
  };
}
