import * as React from "react";
import VisibilitySensor from "react-visibility-sensor";
import Zoom from "react-thumbnail-zoom";
import anime from "animejs";
import "./min.scss";

import { ARTWORKS } from "../../content";

type Props = {
  dark?: boolean;
};

export const Min = (props: Props) => {
  const [isOn, reveal] = React.useState(false);
  const display = (visible: boolean) => {
    if (visible && !isOn) {
      anime({
        targets: `.minGallery`,
        opacity: [0, 1],
        delay: 0,
        translateY: [100, 0],
        complete: () => {
          reveal(true);
        },
        easing: "easeInOutQuad"
      });
    }
  };

  return (
    <section className={`minGallery ${props.dark ? "dark" : ""}`}>
      <h3 className="minGallery__text">
        Take a peek into our gallery
        <span />
      </h3>
      <ul className="minGallery__slideshow">
        {[...ARTWORKS, ...ARTWORKS].map((a, i) => (
          <li className="minGallery__slide">
            <Zoom>
              <img
                onClick={() => console.log("asd")}
                src={a.src}
                alt={`ubuntu gallery image ${i}`}
              />
            </Zoom>
          </li>
        ))}
      </ul>
    </section>
  );
};
