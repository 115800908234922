import * as React from "react";
import Helmet from "react-helmet";
import { Square, CheckSquare, ChevronDown } from "react-feather";
import anime from "animejs";
import VisibilitySensor from "react-visibility-sensor";
import { Button } from "../";

import "./quote.scss";
import * as utils from "../utils";

const services = [
  { name: "Sound" },
  { name: "Stage" },
  { name: "Lighting" },
  { name: "Audio Visuals" },
  { name: "Conferencing" },
  { name: "Sales" },
  { name: "Others" }
];

type Props = {};
type State = {
  showErrors: boolean;
  revealed: boolean;
  name: string;
  email: string;
  company: string;
  message: string;
  start: string;
  venue: string;
  type: string;
  selected: string[];
};

const defaultState = {
  showErrors: false,
  revealed: false,
  name: "",
  email: "",
  company: "",
  message: "",
  start: "",
  venue: "",
  type: "",
  selected: []
};

export class Quote extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...defaultState };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public render() {
    const { showErrors, email, name, type } = this.state;
    return (
      <>
        <Helmet title="Ubuntu Quote" />
        <section className={`quoteForm`}>
          <span className="quoteForm__texture" />
          <VisibilitySensor onChange={this.display} partialVisibility={true}>
            <form
              action={"https://formspree.io/myygknnl"}
              method={"POST"}
              className="quoteForm__wrap"
            >
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="quoteForm__header">
                      <h2 className="quoteForm__title">Please fill in below</h2>
                    </div>
                  </div>
                  {/* <form className="quoteForm__form"> */}
                  <div className="col-sm-4">
                    <div
                      className={`quoteForm__field ${
                        !name && showErrors ? "error" : ""
                      }`}
                    >
                      <label className="quoteForm__field-label">
                        Name
                        <span>(Required)</span>
                      </label>
                      <input
                        name="name"
                        type="text"
                        className="quoteForm__field-input"
                        placeholder="Name"
                        value={name}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div
                      className={`quoteForm__field  ${
                        !email && showErrors ? "error" : ""
                      }`}
                    >
                      <label className="quoteForm__field-label">
                        Email
                        <span>(Required)</span>
                      </label>
                      <input
                        name="email"
                        type="email"
                        className="quoteForm__field-input"
                        placeholder="Email address"
                        value={email}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="quoteForm__field">
                      <label className="quoteForm__field-label">Company</label>
                      <input
                        name="company"
                        type="text"
                        className="quoteForm__field-input"
                        placeholder="Company"
                        value={this.state.company}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="quoteForm__field">
                      <label className="quoteForm__field-label">
                        Event start date
                      </label>
                      <input
                        name="start"
                        type="text"
                        className="quoteForm__field-input"
                        placeholder="Start date"
                        value={this.state.start}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="quoteForm__field">
                      <label className="quoteForm__field-label">
                        Event venue
                      </label>
                      <input
                        name="venue"
                        type="text"
                        className="quoteForm__field-input"
                        placeholder="Venue"
                        value={this.state.venue}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="quoteForm__field">
                      <label className="quoteForm__field-label">
                        Event type
                      </label>
                      <select
                        name="type"
                        className="quoteForm__field-input"
                        placeholder="Extra event info"
                        onChange={this.handleChange}
                        value={type}
                      >
                        <option>Select your event type</option>
                        <option>Conference</option>
                        <option>Wedding</option>
                        <option>Concert</option>
                        <option>Sporting event</option>
                        <option>Corporate events</option>
                        <option>Product launch</option>
                        <option>Awards show</option>
                        <option>Comedy show</option>
                        <option>Other</option>
                      </select>
                      <ChevronDown className="quoteForm__select-icon" />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="quoteForm__field-checksWrap">
                      <label className="quoteForm__field-label">
                        Pick services
                      </label>
                      <div className="quoteForm__field checks">
                        {services.map((service: any, i: number) => {
                          const has = this.state.selected.includes(
                            service.name
                          );
                          return (
                            <div
                              key={i}
                              onClick={this.handleCheckbox.bind(
                                null,
                                service.name
                              )}
                              className={`quoteForm__field-check ${
                                has ? "active" : ""
                              }`}
                            >
                              <input
                                name={service.name}
                                onChange={this.handleNullChage}
                                type="text"
                                value={
                                  has
                                    ? `${service.name} - service selected`
                                    : ""
                                }
                                style={{ float: "left", width: 1 }}
                              />
                              {has ? <CheckSquare /> : <Square />}
                              <span>{service.name}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="quoteForm__field textarea">
                      <label className="quoteForm__field-label">
                        Event info
                      </label>
                      <textarea
                        name="message"
                        value={this.state.message}
                        placeholder="Your event info"
                        onChange={this.handleChange}
                        className="quoteForm__field-input"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <Button
                      size="large"
                      className="quoteForm__submit"
                      onClick={this.handleSubmit}
                      text="Submit"
                      type="submit"
                    />
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </form>
          </VisibilitySensor>
        </section>
      </>
    );
  }

  private handleSubmit = (e: any) => {
    if (!this.isValid()) {
      // e.preventDefault();
      return;
    }
    utils.ga({
      hitType: "event",
      eventCategory: `quote`,
      eventAction: `click`,
      eventLabel: "quote submit"
    });
  };

  private isValid = () => !!this.state.email && !!this.state.name;
  private handleNullChage = () => null;

  private handleChange = (event: any) => {
    const change = {} as any;
    change[event.target.name] = event.target.value;
    this.setState(change);
  };

  private handleCheckbox = (name: string) => {
    if (this.state.selected.includes(name)) {
      this.setState({
        selected: this.state.selected.filter(item => item !== name)
      });
    } else {
      this.setState({ selected: [name, ...this.state.selected] });
    }
  };

  private display = (isVisible: boolean) => {
    if (isVisible && !this.state.revealed) {
      anime({
        targets: ".quote",
        opacity: [0, 1],
        delay: 0,
        duration: 500,
        easing: "easeInOutQuad"
      });
    }
  };
}
