import * as React from "react";
import anime from "animejs";
// import VanillaTilt from "vanilla-tilt";
import VisibilitySensor from "react-visibility-sensor";
import "./extra.scss";

import * as utils from "../utils";

import { SERVICES } from "../../content";
import Curtain from "./curtain";
import { Button } from "../base/button";

const Logo = require("../../assets/svg/logo_color.svg");
const img_1 = require("../../assets/images/hero/hero_a-min.jpg");
const img_2 = require("../../assets/images/hero/hero_b-min.jpg");
const img_3 = require("../../assets/images/hero/hero_c-min.jpg");
const img_4 = require("../../assets/images/hero/hero_d-min.jpg");
const img_5 = require("../../assets/images/hero/hero_e-min.jpg");
const img_6 = require("../../assets/images/hero/hero_f-min.jpg");
const img_7 = require("../../assets/images/hero/hero_g-min.jpg");
const img_8 = require("../../assets/images/hero/hero_h-min.jpg");
const img_9 = require("../../assets/images/hero/hero_i-min.jpg");
const img_10 = require("../../assets/images/hero/hero_j-min.jpg");
const img_11 = require("../../assets/images/hero/hero_k-min.jpg");

export type Area = {
  icon: any;
  id: string;
  title: string;
  image: string | null;
  info: string;
  text: string;
  color: string;
};

type Props = {
  services: Area[];
};

type State = {
  isOn: boolean;
};

export class Extra extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOn: false
    };
  }

  public componentWillMount() {}
  public componentDidMount() {
    // setInterval(() => {
    //   anime({
    //     targets: [".heroExt__bg-center span"],
    //     width: `${100}%`,
    //     duration: 300,
    //     delay: anime.stagger(500),
    //     easing: "easeInOutQuad",
    //     complete: () => {
    //       anime({
    //         targets: [".heroExt__bg-center span"],
    //         width: `${0}%`,
    //         duration: 300,
    //         delay: anime.stagger(50),
    //         easing: "easeInOutQuad"
    //       });
    //     }
    //   });
    // }, 10000);
    // anime({
    //   targets: `.heroExt__bgDots`,
    //   // backgroundImage: `radial-gradient(rgb(0, 0, 0) ${10}px, transparent 0)`,
    //   delay: 200,
    //   duration: 2000,
    //   easing: "easeInOutQuad"
    // });
  }

  public render() {
    return (
      <div className="heroExt">
        <VisibilitySensor onChange={this.display} partialVisibility={true}>
          <>
            <div className="heroExt__bgDots" />

            <div
              className="heroExt__bg-left"
              style={{ backgroundImage: `url('${img_4}')` }}
            >
              <span className="heroExt__bg-curtain" />
            </div>
            <Curtain
              delay={500}
              className="heroExt__bg-center"
              images={[img_1, img_2, img_3, img_4, img_5, img_6, img_7, img_8]}
            />
            <Curtain
              delay={300}
              className="heroExt__bg-right"
              images={[img_2, img_3, img_4, img_5, img_6, img_7, img_8, img_1]}
            />
            {/* <div
              className="heroExt__bg-center"
              style={{ backgroundImage: `url('${img_3}')` }}
            >
              <span className="heroExt__bg-curtain" />
            </div> */}
            {/* <div
              className="heroExt__bg-right"
              style={{ backgroundImage: `url('${img_3}')` }}
            >
              <span className="heroExt__bg-curtain" />
            </div> */}
            <div className="heroExt__top">
              <div
                style={{ zIndex: 5, position: "relative" }}
                className="container"
              >
                <div className="row">
                  <div className="col-sm-12">
                    <div className="heroExt__cta">
                      <h1 className="heroExt__title">
                        Passionate <br />
                        about your
                        <br /> events
                      </h1>
                      <h5 className="heroExt__subtitle">
                        A flexible and creative partner in creating inspiring &
                        engaging experiences.
                      </h5>
                      <Button
                        text="Book us"
                        to="/quote"
                        size="xlarge"
                        bgColor="#e91e63"
                        textColor="white"
                        className="heroExt__book"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </VisibilitySensor>
      </div>
    );
  }

  private display = () => {
    if (!this.state.isOn) {
      anime({
        targets: `#heroExt`,
        opacity: [0, 1],
        delay: 200,
        easing: "easeInOutQuad",
        complete: () => this.setState({ isOn: true })
      });
    }
  };

  static defaultProps = {
    services: SERVICES
  };
}
