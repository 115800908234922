import * as React from "react";
import { navigate } from "gatsby";
import { ArrowRight, Edit2, Check } from "react-feather";
import "./button.scss";
import { utils } from "../";

type Props = {
  onClick?: (e: any) => void;
  icon?: "edit" | "check";
  text: string;
  to?: string;
  type?: string;
  className?: string;
  bgColor?: string;
  textColor?: string;
  outline?: boolean;
  size?: "small" | "large" | "xlarge" | "jumbo";
};

export const Button = ({
  onClick,
  className = "",
  outline,
  text = "Button",
  bgColor,
  textColor,
  type = "button",
  size,
  icon,
  to
}: Props) => {
  const handleClick = (e: any) => {
    if (to) {
      utils.navTo(to);
      return;
    }
    if (onClick) {
      onClick(e);
      return;
    }
  };

  let sz = "";
  switch (size) {
    case "small":
      sz = "small";
      break;
    case "large":
      sz = "large";
      break;
    default:
      break;
  }

  let ico = <ArrowRight style={{ color: textColor }} />;
  switch (icon) {
    case "edit":
      ico = <Edit2 style={{ color: textColor }} />;
      break;
    case "check":
      ico = <Check style={{ color: textColor }} />;
      break;
    default:
      break;
  }

  return (
    <button
      onClick={handleClick}
      className={`button-primary ${sz} ${
        outline ? "outline" : ""
      } ${className}`}
      style={{ backgroundColor: bgColor }}
      type={type}
    >
      <span style={{ color: textColor }}>{text}</span>
      {ico}
    </button>
  );
};
