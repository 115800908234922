import * as React from "react";
import anime from "animejs";
import { Target, Shield, Compass } from "react-feather";
import VisibilitySensor from "react-visibility-sensor";
// import animateScrollTo from "animated-scroll-to";
import "./min.scss";

import * as utils from "../utils";

import { Link, Button } from "../index";
import { VALUES } from "../../content";

const IMAGES = [
  "https://images.unsplash.com/photo-1540190555446-dcc584eabf19?ixlib=rb-1.2.1&auto=format&fit=crop&w=375&q=80 375w",
  "https://images.unsplash.com/photo-1547090218-c525e72ceb96?ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80%20334w",
  "https://images.unsplash.com/photo-1511739001486-6bfe10ce785f?ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80 334w",
  "https://images.unsplash.com/photo-1477949858942-6a62ff5e8e19?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80 334w",
  "https://images.unsplash.com/photo-1551918120-9739cb430c6d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80 334w",
  "https://images.unsplash.com/photo-1527838832700-5059252407fa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=343&q=80 343w"
];

type Props = {
  background?: string;
  index?: 0 | 1 | 2;
  rtl?: boolean;
};
export const Min: React.FunctionComponent<Props> = ({
  background = "#FFFFFFF",
  index,
  rtl
}: Props) => {
  const image = IMAGES[index || 0];
  const value = VALUES[index || 0];
  const id = utils.uuid();
  const [isReversed, reverse] = React.useState(false);
  const [isOn, reveal] = React.useState(false);
  const checkSize = () => reverse(Boolean(rtl && utils.sizeAbove(768)));
  const display = (visible: boolean) => {
    if (visible && !isOn) {
      checkSize();
      anime({
        targets: `.minval__${id}`,
        opacity: [0, 1],
        easing: "easeInOutQuad",
        complete: () => {
          reveal(true);
          anime({
            targets: [".minval__bg-curtain", ".minval__center-curtain"],
            width: `${0}%`,
            delay: anime.stagger(80),
            easing: "easeInOutQuad"
          });
          anime({
            targets: ".minval__right",
            translateX: [-80, 0],
            opacity: [0, 1],
            delay: 100,
            easing: "easeInOutQuad"
          });
          anime({
            targets: [
              ".minval__center-title",
              ".minval__center-tag",
              ".minval__center-btn"
            ],
            translateY: [20, 0],
            opacity: [0, 1],
            delay: anime.stagger(80),
            easing: "easeInOutQuad"
          });
        }
      });
    }
  };

  const getIcon = (title: string): React.ReactElement | null => {
    switch (title) {
      case "philosophy":
        return <Shield size={35} />;
      case "vision":
        return <Compass size={35} />;
      case "mission":
        return <Target size={35} />;
      default:
        return null;
    }
  };

  const renderMain = () => (
    <div className="col-md-8">
      <div
        className="minval__bg"
        style={{ backgroundImage: `url('${image}')` }}
      >
        <div
          className="minval__bg-curtain"
          style={{ backgroundColor: background }}
        />
      </div>
      <Link className="minval__center" to="/about">
        <h5 className="minval__center-title">{value.title}</h5>
        <h3 className="minval__center-tag">{value.tag}</h3>
        <Button size="small" text="About us" className="minval__center-btn" />
        <div
          className="minval__center-curtain"
          style={{ backgroundColor: background }}
        />
      </Link>
    </div>
  );
  const renderPrime = () => (
    <div className="col-md-4" style={{ float: "left" }}>
      <div className="minval__right">
        <p className="minval__right-text">{utils.trunc(value.text, 160)}</p>
        {getIcon(value.title)}
      </div>
    </div>
  );

  return (
    <div
      className={`minval minval__${id} ${isReversed ? "rtl" : ""}`}
      style={{ backgroundColor: background }}
    >
      <VisibilitySensor onChange={display} partialVisibility={true}>
        <div className="container">
          {isReversed ? (
            <div className="row">
              {renderPrime()}
              {renderMain()}
            </div>
          ) : (
            <div className="row">
              {renderMain()}
              {renderPrime()}
            </div>
          )}
        </div>
      </VisibilitySensor>
    </div>
  );
};
