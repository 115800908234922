import * as React from "react";
import VisibilitySensor from "react-visibility-sensor";
import anime from "animejs";
import "./min.scss";

import { CLIENTS, Client } from "../../content";
import { Button } from "../base";
import * as utils from "../utils";

const ani = {
  easing: "easeInOutQuad",
  opacity: [0, 1]
};

type Props = {
  clients: Client[];
  dark?: boolean;
};

type State = {
  isOn: boolean;
  index: number;
  max: number;
};

export class Min extends React.Component<Props, State> {
  private timer: any;
  constructor(props: Props) {
    super(props);

    this.state = {
      isOn: false,
      index: 0,
      max: 0
    };
  }

  componentDidMount() {
    this.setState({ max: this.chunk().length - 1 });
    this.start();
  }
  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  public render() {
    const items = this.chunk()[this.state.index] as [];
    return (
      <section
        id="minClients"
        data-color={this.props.dark ? "dark" : "light"}
        className={`section minClients ${this.props.dark ? "dark" : "light"} `}
      >
        <span
          className="minClients__curtain"
          style={{ backgroundColor: this.props.dark ? "black" : "white" }}
        />

        <VisibilitySensor onChange={this.display} partialVisibility={true}>
          <div className="container">
            <div className="minClients__wrap">
              <div className="minClients__open">
                <Button
                  outline={true}
                  text="About us"
                  to="/about"
                  size="small"
                  bgColor="transparent"
                  textColor={this.props.dark ? "white" : "black"}
                  className="minClients__open-btn"
                />
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="minClients__clients">
                    {items &&
                      items.map((client: Client, key: number) => (
                        <div key={key} className="minClients__client clearfix">
                          <img
                            src={this.props.dark ? client.light : client.dark}
                            className="minClients__img"
                            alt={client.name}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </VisibilitySensor>
      </section>
    );
  }

  private start = () => {
    if(typeof window === 'undefined') return
    this.timer = setInterval(() => {
      anime({
        targets: `.minClients__client`,
        ...ani,
        scale: [1, 0.9],
        opacity: [1, 0],
        duration: 300,
        // delay: anime.stagger(50),
        complete: () => {
          this.setState(
            {
              index:
                this.state.index === this.state.max ? 0 : this.state.index + 1
            },
            () => {
              anime({
                targets: `.minClients__client`,
                ...ani,
                scale: [0.9, 1],
                duration: 300,
                opacity: [0, 1]
                // delay: anime.stagger(50)
              });
            }
          );
        }
      });
    }, 8000);
  };

  private chunk = (): [] => {
    if(typeof window === 'undefined') return [];
    const size = utils.isMobile() ? 2 : utils.isTablet() ? 3 : 4;
    const results = [];
    for (var i = 0; i < this.props.clients.length; i += size) {
      results.push(this.props.clients.slice(i, i + size));
    }
    return results as [];
  };

  private display = (visible: boolean) => {
    if (visible && !this.state.isOn) {
      this.setState({ isOn: true });
      anime({
        targets: `.minClients__client`,
        ...ani,
        delay: anime.stagger(20)
      });
      if (this.props.dark) {
        anime({
          targets: `.minClients__curtain`,
          width: [0, `${100}%`],
          ...ani,
          delay: 0
        });
      }
    }
  };

  // Set default props
  static defaultProps = {
    clients: CLIENTS
  };
}
