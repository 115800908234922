import * as React from "react";
import VisibilitySensor from "react-visibility-sensor";
import anime from "animejs";
import Slider from "react-slick";
import { CheckCircle } from "react-feather";
import "./mid.scss";

import * as utils from "../utils";
import { EXPERTISE, Expertise } from "../../content";

type Props = {};
const init: undefined | Expertise = EXPERTISE[0];
export const Mid: React.FC<Props> = props => {
  const [isOn, reveal] = React.useState(false);
  const [service, setService] = React.useState(init);
  const display = (visible: boolean) => {
    if (visible && !isOn) {
      anime({
        targets: ".expertiseMid",
        opacity: [0, 1],
        easing: "easeInOutQuad",
        duration: 500,
        complete: () => reveal(true)
      });
    }
  };

  const settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    focusOnSelect: true,
    ltr: true,
    arrows: false,
    beforeChange: (_: any, nextSlide: any) => {
      setService(EXPERTISE[nextSlide]);
    }
  };

  return (
    <section className={`expertiseMid`}>
      <VisibilitySensor onChange={display} partialVisibility={true}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-7">
              <div className="expertiseMid__aside">
                <p>{service.label}</p>
                <img src={service.img} alt={`${service.title} image`} />
              </div>
            </div>
            <div className="col-sm-12 col-md-5">
              <Slider {...settings}>
                {EXPERTISE.map((e: Expertise, n) => (
                  <div key={n}>
                    <div className="expertiseMid__area">
                      {/* <span className="expertiseMid__area-icon">
                          <CheckCircle />
                        </span> */}
                      <p className="expertiseMid__label">{e.label}</p>
                      <p className="h3 expertiseMid__title">{e.title}</p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </VisibilitySensor>
    </section>
  );
};
