import * as React from "react";
import anime from "animejs";
import VisibilitySensor from "react-visibility-sensor";
import Slider from "react-slick";
import { ArrowRight, ArrowLeft } from "react-feather";

import "./full.scss";
import { REVIEWS } from "../../content";

type Props = {
  clear?: boolean;
};

export const Full: React.FunctionComponent<Props> = ({ clear }: Props) => {
  const [isOn, reveal] = React.useState(false);
  const display = (visible: boolean) => {
    if (visible && !isOn) {
      anime({
        targets: ".fullFeedback",
        opacity: [0, 1],
        duration: 500,
        delay: 500,
        easing: "easeInOutQuad",
        complete: () => {
          reveal(true);
          anime({
            targets: ".fullFeedback__item",
            opacity: [0, 1],
            translateY: [50, 0],
            delay: anime.stagger(100),
            easing: "easeInOutQuad"
          });
          anime({
            targets: ".fullFeedback__bg",
            opacity: [0, 1],
            delay: 200,
            duration: REVIEWS.length * 100,
            height: [`${0}%`, `${100}%`],
            easing: "easeInOutQuad"
          });
        }
      });
    }
  };
  const SampleNextArrow = ({ to, onClick }: any) => (
    <button
      type="button"
      onClick={onClick}
      className={`fullFeedback__slider-next`}
      aria-label={to}
    >
      <ArrowRight />
    </button>
  );
  const SamplePrevArrow = ({ to, onClick }: any) => (
    <button
      type="button"
      onClick={onClick}
      className={`fullFeedback__slider-prev`}
      aria-label={to}
    >
      <ArrowLeft />
    </button>
  );

  const settings = {
    accessibility: true,
    dots: false,
    className: "fullFeedback__slider",
    infinite: true,
    speed: 500,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 570,
        settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false }
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 1, slidesToScroll: 2, infinite: false }
      },
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2, slidesToScroll: 2, infinite: false }
      }
    ]
  };

  return (
    <div className={`fullFeedback ${clear ? "clear" : ""}`}>
      <VisibilitySensor onChange={display} partialVisibility={true}>
        <div className="container">
          <span className="fullFeedback__bg" />
          <Slider {...settings}>
            {REVIEWS.map((e, i) => (
              <div key={i} className="">
                <div className="fullFeedback__item">
                  <span className="fullFeedback__icon">&ldquo;</span>
                  <p className="fullFeedback__text">{e.text}</p>
                  <p className="fullFeedback__title">{e.title}</p>
                  <p className="fullFeedback__name">{e.name}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </VisibilitySensor>
    </div>
  );
};
