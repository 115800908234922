import * as React from "react";
import VisibilitySensor from "react-visibility-sensor";
import anime from "animejs";
import "./full.scss";

import { CLIENTS, Client } from "../../content";

type Props = {
  title?: string;
  clients?: Client[];
  dark?: boolean;
};

type State = {
  isOn: boolean;
};

export class Full extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOn: false
    };
  }

  public render() {
    return (
      <section
        data-color={this.props.dark ? "dark" : "light"}
        id="clients"
        className={`section clients ${this.props.dark ? "dark" : "light"} `}
      >
        <div className="clients__wrap">
          {this.props.dark && <span className="clients__curtain" />}
          {/* <span className="clients__dots" /> */}
          <VisibilitySensor onChange={this.display} partialVisibility={true}>
            <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <div className="clients__header">
                    <div className="clients__header-wrap">
                      <h2 className="clients__title">clients</h2>
                      <h5 className="clients__subtitle">
                        We make amazing happen for some of the countries most
                        most ambitious companies.
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="clients__clients clearfix">
                    {this.props.clients &&
                      this.props.clients.map((client: Client, key: number) => (
                        <div key={key} className="clients__client">
                          <img
                            src={this.props.dark ? client.light : client.dark}
                            className="clients__img"
                            alt={client.name}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </VisibilitySensor>
        </div>
      </section>
    );
  }

  private display = (visible: boolean) => {
    const ani = {
      easing: "easeInOutQuad",
      opacity: [0, 1]
    };
    if (visible && !this.state.isOn) {
      anime({
        targets: `#clients`,
        translateY: [100, 0],
        delay: 0,
        ...ani,
        complete: () => {
          this.setState({ isOn: true });
          anime({
            targets: `.clients__client`,
            ...ani,
            delay: anime.stagger(30)
          });
        }
      });
      anime({
        targets: `.clients__curtain`,
        width: [0, `${100}%`],
        ...ani,
        delay: 0
      });
    }
  };

  // Set default props
  static defaultProps = {
    clients: CLIENTS
  };
}
