import * as React from "react";
import VisibilitySensor from "react-visibility-sensor";
import anime from "animejs";
import { Mail } from "react-feather";

import "./cta.scss";
import {  Link } from "../index";

type Props = {
  title?: string;
  email?: string;
  buttonText?: string;
  buttonLink?: string;
};
export const CTA = (props: Props) => {
  const [isOn, reveal] = React.useState(false);
  const display = (visible: boolean) => {
    if (visible && !isOn) {
      anime({
        targets: ".cta__content",
        opacity: [0, 1],
        translateY: [30, 0],
        duration: 500,
        delay: 300,
        easing: "easeInOutQuad",
        complete: () => reveal(true)
      });
      anime({
        targets: ".cta__bg",
        duration: 400,
        width: `${100}%`,
        easing: "linear"
      });
    }
  };
  
  return (
    <section data-color="light" className="section cta">
      <span className="cta__bg" />
      <VisibilitySensor onChange={display} partialVisibility={true}>
          <div className="container">
            <div className="cta__wrap">
              <h5>For sponsorships, charities or requests, contact us</h5>
              <Link
                className="cta__link"
                to="mailto:hello@ubuntuevents.co"
              >
                <h3>
                  <Mail className="cta__icon" size="20" />
                  hello@ubuntuevents.co
                </h3>
              </Link>
            </div>
          </div>
      </VisibilitySensor>
    </section>
  );
};
