import * as React from "react";
import anime from "animejs";
import VisibilitySensor from "react-visibility-sensor";
import { utils } from "../";
import "./intro.scss";

type Props = {
  title: string;
  image?: string;
};

type State = {
  isOn: boolean;
  id: string;
};

export class Intro extends React.Component<Props, State> {
  private resizeLintener: any;
  private scrollLintener: any;
  constructor(props: Props) {
    super(props);

    this.state = {
      isOn: false,
      id: utils.uuid()
    };
  }

  componentWillUnmount() {
    if (this.resizeLintener) {
      window.removeEventListener("resize", this.resizeLintener);
    }
    if (this.scrollLintener) {
      window.removeEventListener("scroll", this.scrollLintener);
    }
  }
  
  public render() {
    const { image, title } = this.props;
    const { id } = this.state;
    return (
      <VisibilitySensor onChange={this.display} partialVisibility={true}>
        <div className={`exoIntro exoIntro_${id}`}>
          <div
            className={`exoIntro__img exoIntro__img_${id}`}
            style={{ backgroundImage: `url('${image}')` }}
          />
          <div className="container">
            <h1 className={`exoIntro__title exoIntro__title_${id}`}>
              {title}
            </h1>
            <div className={`exoIntro__line exoIntro__line_${id}`} />
          </div>
        </div>
      </VisibilitySensor>
    );
  }

  private display = (vissible: boolean) => {
    const { id, isOn } = this.state;
    if (vissible && !isOn) {
      const height = window.innerHeight;
      const titleH =
        utils.getElementHeights([`.exoIntro__title_${this.state.id}`]) ||
        height / 2;
      const line = document.querySelector(
        `.exoIntro__line_${id}`
      ) as any;
      const main = document.querySelector(`.exoIntro_${id}`) as any;
      main.style.height = `${height}px`;
      main.style.height = `${height}px`;
      line.style.top = `${titleH + 20}px`;

      if (this.props.image) {
        const newImg = new Image();
        newImg.onload = () => {
          this.assemble(window.innerHeight);
        };
        newImg.src = this.props.image as any;
      }
    }
  };

  private assemble = (height: number) => {
    const { id } = this.state;
    const el = document.querySelector(`.exoIntro__img_${id}`) as any;
    const easing = { easing: "easeInOutQuad" };
    if (el) {
      el.style.height = `${(height / 3.5) * 1.8}px`;
      el.style.top = `${height}px`;
      anime({
        targets: `.exoIntro__line_${id}`,
        height: [0, `${height / 2 + height / 10}px`],
        ...easing
      });
      anime({
        targets: `.exoIntro__img_${id}`,
        top: [`${height}px`, `${height / 2}px`],
        ...easing,
        complete: () => {
          this.scrollLintener = window.addEventListener("scroll", () => {
            utils.throttle(() => {
              const pos = window.pageYOffset;
              let top = window.innerHeight / 2 - pos / 0.3;
              if (top < 90) return;
              el.style.top = `${top}px`;
            }, 500)();
          });
          this.resizeLintener = window.addEventListener("resize", () => {
            utils.throttle(() => {
              el.style.height = `${(window.innerHeight / 3.5) * 1.8}px`;
            }, 500)();
          });
        }
      });
    }
  };
}
